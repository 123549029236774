import * as React from "react";
import { Link } from "gatsby";
import Layout from '../components/layouts/layout';
import Seo from "@/components/Seo";

const NotFoundPage = () => {
	return (
		<Layout>
			<section className="flex justify-center items-center" id="contact">
				<div className="max-w-[320px] mx-auto my-[60px]">
				<h1 className="text-[45px]">404</h1>
				<p className="text-[18.75px] mt-[10px]">ページが見つかりませんでした。</p>
				<p className="text-[12.5px] mt-[30px]">
					アクセスしようとしたページは見つかりませんでした。
				</p>
				<button className="text-[18.75px] mt-[30px] bg-cyan-600 py-[10px] px-[10px] rounded hover:bg-cyan-800 duration-200 transition-colors">
					<Link to="/" className="text-white">ホームに戻る</Link>
				</button>
				</div>
			</section>
		</Layout>
	)
}

export default NotFoundPage;

export const Head = () => (
    <Seo
        title="Not found | 8LIFE"
    />
);
