import React from 'react';
import { Class as Classes } from '@/constants/vars';
import ArrowDown from '@/assets/images/navi/subsidy/arrow_down.png';
import MoreMobile from '@/assets/images/navi/subsidy/more_m.png';
import MoreDesktop from '@/assets/images/navi/subsidy/more_d.png';
import Present1 from '@/assets/images/navi/subsidy/present_1.png';
import Present2 from '@/assets/images/navi/subsidy/present_2.png';
import Female from '@/assets/images/navi/subsidy/female.png';
import Male from '@/assets/images/navi/subsidy/male.png';

const Subsidy = () => {
    return (
        <section
            className={Classes.Section_Home}>
            <div
                className='
                    w-full flex justify-center px-2.5
                    sm:px-5
                    md:px-10
                    lg:px-14
                    xl:px-24
                '>
                <div
                    className='
                        w-full max-w-[1224px] flex flex-col items-center
                    '>
                    <div
                        className='
                            w-full relative bg-white rounded-t-[7px]
                            md:rounded-t-[28px]
                            lg:rounded-t-[50px]
                        '>
                        <div
                            className='
                                w-[87px] absolute top-0 left-[16px] -translate-y-1/2
                                md:w-[127px] md:top-[unset] md:bottom-[50px] md:left-[unset] md:right-0 md:translate-x-[35px]
                                lg:w-[168px] lg:bottom-[96px] lg:translate-x-[50px]
                                xl:translate-x-1/2
                            '>
                            <img
                                src={Female}
                                alt='female'
                                sizes='100%'
                            />
                        </div>
                        <div
                            className='
                                w-full flex flex-col items-center px-2.5 pt-[79px] pb-[76px] gap-y-[41px]
                                md:px-5 md:pt-[57px] md:pb-[87px] md:gap-y-[82px]
                                lg:px-10 lg:pt-[36px] lg:pb-[99px] lg:gap-y-[123px]
                            '>
                            <div
                                className='
                                    w-full flex flex-col-reverse items-center gap-y-[21px]
                                    md:flex-row md:items-start md:justify-start md:gap-x-[5px]
                                '>
                                <div
                                    className='
                                        w-[62%] max-w-[300px]
                                        md:w-[34%] md:min-w-[250px] md:max-w-[420px]
                                        lg:min-w-[312px]
                                    '>
                                    <img
                                        src={Present1}
                                        alt='present-1'
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full flex flex-col items-center gap-y-[48px]
                                        md:flex-1 md:items-start md:gap-y-[65px] md:pt-[147px]
                                        lg:gap-y-[82px] lg:pt-[200px]
                                    '>
                                    <div
                                        className='
                                            w-full font-bold text-[22px] text-center leading-[175%]
                                            md:text-[30px] md:text-start
                                            lg:text-[39px]
                                        '>
                                        <p
                                            className='
                                                leading-[175%]
                                            '>
                                            補助金・助成金には
                                        </p>
                                        <p
                                            className='
                                                leading-[175%]
                                            '>
                                            <span
                                                className='
                                                    text-[#D30D58] border border-[#D30D58]
                                                '>
                                                『もったいない』
                                            </span>&nbsp;
                                            <span
                                                className='
                                                    font-normal text-[14px]
                                                    md:text-[19px]
                                                    lg:text-[25px]
                                                '>
                                                がたくさん！
                                            </span>
                                        </p>
                                    </div>
                                    <p
                                        className='
                                            text-[12px] text-center leading-[300%]
                                            md:text-[15px] md:text-start
                                            lg:text-[18px]
                                        '>
                                        <span
                                            className='
                                                font-bold
                                            '>
                                            ほとんどの補助金・助成金は<br />
                                            該当していても申請しないと受給できません。<br />
                                            それなのに情報の案内がお手元に届くわけでもありません。<br />
                                        </span>
                                        活用できる補助金・助成金は意外と多くあります。<br />
                                        しかし、情報を定期的に探すには<span className='font-bold text-[#D30D58]'>労力が必要</span>で、<br />
                                        <span className='font-bold text-[#D30D58]'>見逃されている情報</span>も多くあります。
                                    </p>
                                </div>
                            </div>
                            <div
                                className='
                                    w-[41.68px]
                                    md:w-[64px]
                                    lg:w-[88px]
                                '>
                                <img
                                    src={ArrowDown}
                                    alt='arrow-down'
                                    sizes='100%'
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className='
                            w-full relative bg-[#06B6D4] rounded-b-[7px]
                            md:rounded-b-[28px]
                            lg:rounded-b-[50px]
                        '>
                        <div
                            className='
                                w-[87px] absolute top-0 right-[16px] -translate-y-1/2
                                md:w-[127px] md:top-[unset] md:bottom-[50px] md:right-0 md:translate-x-[35px]
                                lg:w-[168px] lg:bottom-[96px] lg:translate-x-[50px]
                                xl:translate-x-1/2
                            '>
                            <img
                                src={Male}
                                alt='male'
                                sizes='100%'
                            />
                        </div>
                        <div
                            className='
                                w-full relative flex flex-col items-center pt-[86px] gap-y-[48px]
                                md:pt-[92px] md:gap-y-[71px]
                                lg:pt-[98px] lg:gap-y-[95px]
                            '>
                            <div
                                className='
                                    w-full flex flex-col items-center gap-y-[43px] px-2.5
                                    md:flex-row md:justify-start md:items-start md:gap-x-5 md:px-5
                                    lg:px-10
                                '>
                                <div
                                    className='
                                        w-[73%] max-w-[300px]
                                        md:w-[26%] md:max-w-none min-w-[280px]
                                        lg:min-w-[320px]
                                    '>
                                    <img
                                        src={Present2}
                                        alt='present-2'
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full flex flex-col items-center gap-y-[48px]
                                        md:items-start md:gap-y-[63px] md:pt-[45px]
                                        lg:gap-y-[72px]
                                    '>
                                    <div
                                        className='
                                            font-bold text-[22px] text-center text-white leading-[175%]
                                            md:text-[30px] md:text-start
                                            lg:text-[39px]
                                        '>
                                        <p
                                            className='
                                                leading-[175%]
                                            '>
                                            <span
                                                className='
                                                    text-[#06B6D4] bg-white px-2
                                                '>
                                                8LIFE
                                            </span>&nbsp;
                                            <span
                                                className='
                                                    font-normal text-[14px]
                                                    md:text-[19px]
                                                    lg:text-[24px]
                                                '>
                                                なら
                                            </span>
                                        </p>
                                        <p
                                            className='
                                                leading-[175%]
                                            '>
                                            使える補助金・助成金が<br className='md:hidden' />
                                            すぐ分かる！
                                        </p>
                                    </div>
                                    <p
                                        className='
                                            text-[12px] text-center leading-[300%] text-white
                                            md:text-[15px] md:text-start
                                            lg:text-[18px]
                                        '>
                                        どんな方でも使いやすく、<br className='md:hidden'/>
                                        補助金・助成金などの<br />
                                        様々な支援情報がきっと見つかります。
                                    </p>
                                </div>
                            </div>
                            <div
                                className='
                                    w-full max-w-[400px]
                                    md:max-w-none
                                '>
                                <div
                                    className='
                                        w-full
                                        md:hidden
                                    '>
                                    <img
                                        src={MoreMobile}
                                        alt='arrow-down'
                                        className='w-full'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full hidden
                                        md:block
                                    '>
                                    <img
                                        src={MoreDesktop}
                                        alt='arrow-desktop'
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Subsidy;