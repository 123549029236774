import React from 'react';
import MediaImage from '@/assets/images/global/media.png';
import { twMerge } from 'tailwind-merge';

const Media = ({ className }: { className?: string }) => {
    return (
        <section
            className={twMerge(`
                mt-12 flex justify-center px-3 
                sm:mt-14 sm:px-6
                md:mt-20
                lg:px-9
            `, className)}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full max-w-[650px] flex flex-col gap-y-6
                        sm:gap-y-7
                        md:flex-row md:gap-x-6
                    '>
                    <a
                        href={'https://media.joa-holdings.com/'}
                        rel='media'
                        target='_blank'
                        className='
                            w-full h-full flex-1 overflow-hidden rounded-md transition duration-300 ease-outQuad block
                            hover:scale-105
                        '>
                        <div className='relative w-full h-full'>
                            <img
                                src={MediaImage}
                                alt='media'
                                sizes='100%'
                            />
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Media;