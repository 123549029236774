import React from 'react';
import { Class } from '@/constants/vars';
import NotePart from '@/components/element/home/NotePart';

const Note = () => {
    return (
        <section
            className={`
                ${Class.Section_Home}
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <NotePart />
            </div>
        </section>
    );
}

export default Note;