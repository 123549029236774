import React from 'react';
import FeatureItem from '@/components/element/sales/FeatureItem';
import Stars from '@/assets/images/sales/global/stars.svg';
import Vars from '@/constants/vars';
import Any from '@/components/global/Any';

const FeaturePoints: FeatureType[] = [
    {
        icon: require('@/assets/images/sales/feature/feature_1.png').default,
        description: <>
            役立つ情報を<br className='hidden md:block' />
            <span className='text-[#06B6D4]'>簡単に提供</span>
        </>
    },
    {
        icon: require('@/assets/images/sales/feature/feature_2.png').default,
        description: <>
            <span className='text-[#06B6D4]'>提案履歴が</span><br className='hidden md:block' />
            保存可能
        </>
    },
    {
        icon: require('@/assets/images/sales/feature/feature_3.png').default,
        description: <>
            <span className='text-[#06B6D4]'>提案資料(PDF)</span><br className='hidden md:block' />
            作成機能
        </>
    },
    {
        icon: require('@/assets/images/sales/feature/feature_4.png').default,
        description: <>
            <span className='text-[#06B6D4]'>簡単・正確</span>な<br className='hidden md:block' />
            情報検索
        </>
    },
    {
        icon: require('@/assets/images/sales/feature/feature_5.png').default,
        description: <>
            <span
                className='
                    text-[12px]
                    md:text-[16px]
                    lg:text-[20px]
                '>
                事業者向けの
            </span><br />
            補助金・助成金<br className='hidden md:block'/>
            について<br />
            <span className='text-[#06B6D4]'>無料相談が可能</span>
        </>
    },
]

function Features() {
    return (
        <section
            className='
                w-full flex justify-center mt-[4rem]
                md:mt-[5rem]
                lg:mt-[8rem]
            '>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full flex flex-col items-center space-y-6
                        sm:space-y-9
                        md:space-y-10
                    '>
                    <Any
                        from={Vars.Animation.Title.from}
                        to={[
                            {
                                state: Vars.Animation.Title.to,
                                duration: Vars.Animation.Title.Duration,
                                easing: Vars.Animation.Title.Easing
                            }
                        ]}>
                        <p
                            className='
                                text-lg font-bold
                                sm:text-[22.5px]
                                md:text-3xl
                            '>
                            <span>選ばれる</span>
                            <span
                                className='relative whitespace-nowrap'>
                                <span
                                    className='
                                        w-4 absolute bottom-full left-full select-none
                                        sm:w-5
                                        md:w-[22.5px]
                                        lg:w-6
                                    '>
                                    <img
                                        src={Stars}
                                        alt='stars'
                                        sizes='100%'
                                    />
                                </span>
                                <span
                                    className='
                                        text-[22.5px] text-[#D30D58]
                                        sm:text-3xl
                                        md:text-4xl
                                    '>
                                    理由
                                </span>
                            </span>
                        </p>
                    </Any>
                    <div
                        className='
                            w-full flex justify-center bg-[#D1F3F9] py-6 px-2.5
                            sm:py-12 sm:px-5
                            md:py-[60px] md:px-10
                            lg:py-[70px]
                        '>
                        <div
                            className='
                                w-full max-w-[320px] grid grid-cols-1 gap-6
                                sm:gap-10
                                md:max-w-[1127px] md:grid-cols-2 md:gap-9
                                lg:gap-10
                            '>
                            {
                                FeaturePoints.map((feature, index) =>
                                    <FeatureItem
                                        key={index}
                                        feature={feature}
                                        index={index}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;