import React from 'react';
import pb from '@/assets/images/sales/pb.svg';

const UseCaseItem = ({ useCase }: { useCase: UseCaseType }) => {
    return (
        <div className='h-full w-full relative'>
            <div
                className='
                    absolute inset-0 rounded-lg overflow-hidden pointer-events-none select-none
                '>
                <img
                    alt='image'
                    src={pb}
                    sizes='100%'
                    className='w-full h-full object-cover'
                />
            </div>

            <div
                className='
                    w-full h-full flex flex-col items-center px-3 pt-6 pb-3 space-y-1.5
                    sm:px-5 sm:pt-9 sm:pb-6 sm:space-y-3
                    md:px-6 md:pt-12 md:pb-9 md:space-y-6
                '>
                <div
                    className='
                        w-full flex flex-col gap-y-3
                        sm:gap-y-6
                        md:gap-y-9
                    '>
                    <div
                        className='
                            w-full relative flex items-center gap-x-1.5
                            sm:gap-x-3
                            md:justify-start md:gap-x-4
                        '>
                        <img
                            alt='image'
                            src={useCase.icon}
                            className='
                                relative aspect-square w-[60px]
                                sm:w-20
                                md:w-[70px]
                                xl:w-[100px]
                                object-contain
                            '
                        />
                        <div
                            className='
                                relative flex-col space-y-1
                                sm:space-y-[7.5px]
                                md:space-y-[10px] md:flex
                            '>
                            <h2
                                className='
                                    text-base font-extrabold whitespace-nowrap
                                    md:text-lg
                                    lg:text-[22.5px]
                                '>
                                {useCase.name}
                            </h2>
                            <p
                                className='
                                    text-[3.237vw] whitespace-nowrap
                                    sm:text-[12.5px]
                                    lg:text-[0.9vw]
                                '>
                                {useCase.role}
                            </p>
                        </div>
                    </div>
                    <div
                        className='
                            justify-start
                            md:w-full md:flex
                        '>
                        <img
                            alt='image'
                            src={useCase.companyIcon}
                            className='
                                h-5 w-fit relative object-contain
                                sm:h-6
                                md:h-7
                            '
                        />
                    </div>
                </div>
                <div
                    className='
                        flex-1 relative space-y-[10px]
                        lg:space-y-5
                    '>
                    <p
                        className='
                            text-[14px] font-bold
                            md:text-[16px] md:min-h-[90px]
                            lg:text-[1.261vw] lg:min-h-[70px]
                            xl:text-[18px] xl:min-h-[85px]
                        '>
                        {useCase.summary}
                    </p>
                    <div
                        className='
                            text-[14px]
                            md:text-[15px]
                            lg:text-[16px]
                        '>
                        {useCase.description}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UseCaseItem;