import React from 'react';
import Any from '@/components/global/Any';
import Vars from '@/constants/vars';

const Class = {
    'Title': `
        bg-[#06B6D4] text-white z-10 text-lg text-center font-medium px-[10px] py-[5px]
        md:text-[22.5px] md:px-[15px] md:py-[8px]
        lg:px-[20px] lg:py-[10px]
    `
}

const FlowItem = ({ flow, no }: { flow: FlowSales, no: number }) => {
    return (
        <div
            className='
                w-full flex justify-center bg-[#E2FAFE] rounded-xl px-2.5
            '>
            <div
                className='
                    w-full relative                    
                '>
                {
                    no !== 0 &&
                    <div
                        className={`
                            ${Class.Title} w-max absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2
                        `}>
                        {flow.title}
                    </div>
                }
            
                <div
                    className='
                        w-full flex flex-col items-center py-10 gap-y-6
                        sm:px-6 sm:py-12 sm:gap-y-7
                        md:px-12 md:py-[70px] md:gap-y-12
                        lg:py-20
                    '>
                    {
                        no === 0 &&
                        <p
                            className={`
                                ${Class.Title} w-fit
                                md:w-max md:absolute md:top-0 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2
                            `}>
                            {flow.title}
                        </p>
                    }
                    <div
                        className='
                            w-full max-w-[246px] grid grid-cols-3 gap-x-[30px] gap-y-[30px]
                            md:max-w-[548px] md:flex md:flex-wrap md:justify-center md:gap-x-[40px] md:gap-y-[45px]
                            lg:max-w-[826px] lg:gap-x-[50px] lg:gap-y-[59px]
                        '>
                        {
                            flow.points.map((point, index) =>
                                <Any
                                    key={index}
                                    className='
                                        w-full flex flex-col items-center
                                        md:max-w-[76px]
                                        lg:max-w-[96px]
                                    '
                                    from={Vars.Animation.Item.from}
                                    to={[
                                        {
                                            state: Vars.Animation.Item.to,
                                            duration: Vars.Animation.Item.Duration,
                                            easing: Vars.Animation.Item.Easing,
                                            delay: index * (Vars.Animation.Item.Delay || 0)
                                        }
                                    ]}>
                                    <div
                                        className='
                                            w-full
                                        '>
                                        <img
                                            alt='image'
                                            src={point.icon}
                                            sizes='100%'
                                        />
                                    </div>
                                    <div
                                        className='
                                            text-[11.25px] text-center
                                            sm:text-[12.5px]
                                            md:text-base
                                        '>
                                        {point.description}
                                    </div>
                                </Any>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlowItem;