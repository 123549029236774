import React from 'react';
import Vars from '@/constants/vars';
import Any from '@/components/global/Any';

const NotePart = () => {
    return (
        <div
            className='
                space-y-6 flex flex-col items-center
                sm:space-y-9
                md:space-y-10
            '>
            <Any
                from={Vars.Animation.Title.from}
                to={[
                    {
                        state: Vars.Animation.Title.to,
                        duration: Vars.Animation.Title.Duration,
                        easing: Vars.Animation.Title.Easing
                    }
                ]}>
                <h3
                    className='
                        text-lg text-[#394455] text-[16px] text-center
                        md:text-[21px]
                        lg:text-[25px]
                    '>
                    注意事項
                </h3>
            </Any>

            <Any
                from={Vars.Animation.Image.from}
                to={[
                    {
                        state: Vars.Animation.Image.to,
                        duration: Vars.Animation.Image.Duration,
                        easing: Vars.Animation.Image.Easing
                    }
                ]}>
                <ul
                    className='
                        w-full max-w-fit text-[#394455] text-[14px] flex flex-col gap-y-1.5 px-6
                        sm:gap-y-[7.5px]
                        md:text-[15px]
                        lg:text-[16px]
                    '>
                    <li>※ キャンペーンは予告なしに終了する場合がございます。</li>
                    <li>※ 月間利用を解約する場合、翌月末日までの利用料金が発生します。</li>
                    <li>※ 年間利用を途中解約した場合でも受領済みの残存期間分の利用料金は返金されません。</li>
                    <li>※ 年間利用の場合、利用開始時に年額を一括でお支払いいただきます。</li>
                    <li>
                        ※ 一部キャリアメール等では設定により登録案内メールが届かない場合がございます。<br />
                        　別のアドレスで再度お試しいただくか、お問い合わせくださいませ。
                    </li>
                    <li>※ 同一ＩＤで複数端末での同時ログインはできません。</li>
                </ul>
            </Any>
        </div>
    );
}

export default NotePart;