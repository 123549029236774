import React from 'react';
import StepMobile from '@/assets/images/sales/step/step_m.png';
import StepDesktop from '@/assets/images/sales/step/step_d.png';
import Curry from '@/assets/images/sales/global/curry.svg';
import Glow from '@/components/global/Glow';
import Any from '@/components/global/Any';
import Vars from '@/constants/vars';

const Class = {
    Glow: `
        w-[1000px] opacity-30
        sm:w-[1200px] sm:opacity-40
        md:w-[1300px] md:opacity-60
        lg:w-[1500px] lg:opacity-70
    `
}

function Step() {
    return (
        <section
            className="
                mt-12 flex justify-center
                sm:mt-14
                md:mt-20
            ">
            <div
                className='w-full'>
                <div
                    className='
                        relative w-full flex flex-col items-center space-y-9
                        sm:space-y-12
                        md:space-y-20
                    '>
                    <Any
                        from={Vars.Animation.Title.from}
                        to={[
                            {
                                state: Vars.Animation.Title.to,
                                duration: Vars.Animation.Title.Duration,
                                easing: Vars.Animation.Title.Easing
                            }
                        ]}>
                        <h2
                            className="
                                text-lg text-center font-bold
                                sm:text-[22.5px]
                                md:text-3xl
                            ">
                            <span>保険営業でこんな</span>
                            <span
                                className='
                                    relative text-[#D30D58] text-[22.5px] whitespace-nowrap
                                    sm:text-3xl
                                    md:text-4xl
                                '>
                                <span
                                    className='
                                        w-4 absolute bottom-full left-full -translate-x-1/2 select-none
                                        sm:w-5
                                        md:w-[22px]
                                        lg:w-6
                                    '>
                                    <img
                                        src={Curry}
                                        alt='curry'
                                        sizes='100%'
                                    />
                                </span>
                                <span>お悩み</span>
                            </span>
                            <span>ありませんか？</span>
                        </h2>
                    </Any>
                
                    <Any
                        className="
                            w-full max-w-[274px] relative flex items-center justify-center select-none pointer-events-none
                            md:max-w-[720px]
                            lg:max-w-[900px]
                        "
                        from={Vars.Animation.Image.from}
                        to={[
                            {
                                state: Vars.Animation.Image.to,
                                duration: Vars.Animation.Image.Duration,
                                easing: Vars.Animation.Image.Easing
                            }
                        ]}>
                        <img
                            src={StepMobile}
                            alt='step-mobile'
                            sizes='100%'
                            className='md:hidden'
                        />
                        <img
                            src={StepDesktop}
                            alt='step-desktop'
                            sizes='100%'
                            className='hidden md:inline'
                        />

                        <Glow
                            size='20'
                            className={`
                                ${Class.Glow} top-0 left-0 -translate-x-[50%] -translate-y-[40%]
                            `}
                            >
                        </Glow>

                        <Glow
                            size='20'
                            className={`
                                ${Class.Glow} bottom-0 right-0 translate-x-[40%] translate-y-[40%]
                            `}>
                        </Glow>
                    </Any>
                </div>
            </div>
        </section>
    )
}

export default Step;