import React from "react";
import Layout from "../../components/layouts/layout";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Seo from "@/components/Seo";

export const SingleData = graphql`
    query SinglePost($slug: String) {
        sanityPost(slug: { current: { eq: $slug } }) {
            title
            mainImage {
                asset {
                    url
                }
            }
            publishedAt(formatString: "YYYY年 MM月 DD日")
            _rawBody
        }
    }
`;

const BlogPost = ({ data }) => {
    const post = data.sanityPost;
    const { title, publishedAt, _rawBody } = post;
    const Image = post.mainImage.asset.url;

    return (
        <>
            <Layout>
                <section id="content">
                    <div className="default__width">
                        <div className="content">
                            <div className="content--img">
                                <img src={Image} alt={title} />
                            </div>
                            <div>
                                <p className="content--date">{publishedAt}</p>{" "}
                            </div>
                            <h2 className="content--title">{title}</h2>
                            <div className="content--detail">
                                {" "}
                                <PortableText value={_rawBody} />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default BlogPost;

export const Head = () => (
    <Seo
        title="Blog | 8LIFE"
    />
  );