import React from 'react';
import Q from '@/assets/images/navi/faq/q.png';
import A from '@/assets/images/navi/faq/a.png';
import Any from '@/components/global/Any';
import Vars from '@/constants/vars';

type Props = {
    qa: {
        question: string,
        answer: React.ReactNode
    }
}

const Class = {
    BoxWrapper: `
        w-full flex gap-x-5 border
        md:gap-x-8
        lg:gap-x-12
    `,
    QA: `
        h-[55px] aspect-square
        md:h-[60px]
    `,
    Text_QA: `
        text-[#394455] text-[14px] py-[16px] pr-2.5
        md:text-[15px] md:pr-3
        lg:text-[16px] lg:pr-5
    `
}

const QA = ({ qa }: Props) => {
    return (
        <div
            className='
                w-full flex flex-col gap-y-8
                md:ga-y-7
                lg:gap-y-5
            '>
            <Any
                className={`
                    ${Class.BoxWrapper} border-[#06B6D4] bg-white
                `}
                from={Vars.Animation.Item.from}
                to={[
                    {
                        state: Vars.Animation.Item.to,
                        duration: Vars.Animation.Item.Duration,
                        easing: Vars.Animation.Item.Easing
                    }
                ]}>
                <div
                    className={`
                        ${Class.QA}
                    `}>
                    <img
                        src={Q}
                        alt="q"
                        className='h-full'
                    />
                </div>
                <p
                    className={`
                        ${Class.Text_QA} font-medium
                    `}>
                    {qa.question}
                </p>
            </Any>
            <Any
                className={`
                    ${Class.BoxWrapper} border-[#243133] bg-white
                `}
                from={Vars.Animation.Item.from}
                to={[
                    {
                        state: Vars.Animation.Item.to,
                        duration: Vars.Animation.Item.Duration,
                        easing: Vars.Animation.Item.Easing
                    }
                ]}>
                <div
                    className={Class.QA}>
                    <img
                        src={A}
                        alt="a"
                        className='h-full'
                    />
                </div>
                <div
                    className={Class.Text_QA}>
                    {qa.answer}
                </div>
            </Any>
        </div>
    )
}

export default QA;