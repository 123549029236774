import React from 'react';
import Vars, { Class } from '@/constants/vars';
import Any from '@/components/global/Any';
import Trial from '@/components/global/Trial';

const Note = () => {
    return (
        <section
            className={`
                ${Class.Section_Home}
            `}>
            <div
                id='note'
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full space-y-6
                        sm:space-y-7
                        md:space-y-9
                    '>
                    <Any
                        className='flex justify-center'
                        from={Vars.Animation.Button.from}
                        to={[
                            {
                                state: Vars.Animation.Button.to,
                                duration: Vars.Animation.Button.Duration,
                                easing: Vars.Animation.Button.Easing
                            }
                        ]}>
                        <a
                            href="https://app.8life.cloud/auth/register?plan=default"
                            rel='register'
                            target='_blank'
                            className='
                                w-[90%] max-w-[400px] px-1 py-3 text-[1rem] font-bold transition-colors duration-200 bg-[#D30D58] rounded-md centralize text-white border border-[#D30D58]
                                md:w-full md:max-w-[500px] md:text-[1.125rem] md:py-5
                                lg:max-w-[550px] lg:text-[18px] lg:py-[1.25rem] lg:rounded-[0.375rem]
                                hover:text-[#D30D58] hover:bg-white
                            '>
                            今すぐ無料トライアル
                        </a>
                    </Any>

                    <Trial />
                </div>
            </div>
        </section>
    );
}

export default Note;