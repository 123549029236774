import React from 'react';

const SpaceBottom = () => {
    return (
        <div
            className='
                h-[100px]
                md:h-[150px]
                lg:h-[200px]
            '>
        </div>
    );
}

export default SpaceBottom;