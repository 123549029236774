import React from 'react';
import NewsImage from '@/assets/images/global/news.png';

const News = () => {
    return (
        <section
            className={`
                mt-12 flex justify-center px-3 
                sm:mt-14 sm:px-6
                md:mt-20
                lg:px-9
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full max-w-[650px] flex flex-col gap-y-6
                        sm:gap-y-7
                        md:flex-row md:gap-x-6
                    '>
                    <a
                        href={'https://www.joa-holdings.com/news/%E7%AC%AC2%E5%9B%9E%E5%85%AC%E7%9A%84%E6%94%AF%E6%8F%B4%E6%B4%BB%E7%94%A8%E3%82%B7%E3%83%B3%E3%83%9D%E3%82%B8%E3%82%A6%E3%83%A0%E3%82%92%E9%96%8B%E5%82%AC%E3%81%84%E3%81%9F%E3%81%97%E3%81%BE%E3%81%97%E3%81%9F'}
                        rel='news'
                        target='_blank'
                        className='
                            w-full h-full flex-1 overflow-hidden rounded-md transition duration-300 ease-outQuad block
                            hover:scale-105
                        '>
                        <div className='relative w-full h-full'>
                            <img
                                src={NewsImage}
                                alt='news'
                                sizes='100%'
                            />
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default News;