import Any from '@/components/global/Any';
import Glow from '@/components/global/Glow';
import Vars, { Class } from '@/constants/vars';
import React from 'react';

const QA: {
    _id: number,
    question: string,
    answer: React.ReactNode
}[] = [
    {
        _id: 1,
        'question' : 'どのような情報が入っていますか？',
        'answer' : '省庁、市区町村、外郭機関を含めて、2000箇所以上の自治体の公的保険・支援情報が約12万件以上入っています。'
    },
    {
        _id: 2,
        'question' : 'どのデバイスで利用可能ですか？',
        // 'answer' : 'PC、スマートフォンおよびタブレット端末からのご利用が可能です。'
        'answer' : 'インターネットが利用可能な環境で、PC、スマートフォンおよびタブレット端末からのご利用が可能です。'
    },
    {
        _id: 3,
        'question' : 'セキュリティは安心ですか？',
        'answer' : <>
            8LIFEでは取り扱うすべてのデータを世界トップレベルのクラウドセキュリティを誇るAWSで保存しております。<br/>
            セキュリティ機能の搭載、外部攻撃に対する対策などがなされております。
            {/* また、弊社はプライバシーマーク、ISO27001認証を取得済みです */}
        </>
    },
    {
        _id: 4,
        'question' : 'どのような支払い方法がありますか？',
        // 'answer' : '銀行振込またはクレジット決済が可能です。その他のお支払い方法をご希望の場合は、弊社までお問い合わせください。'
        answer: 'クレジット決済が可能です。その他のお支払い方法をご希望の場合は、弊社までお問い合わせください。',
    },
    {
        _id: 5,
        // 'question' : '公的保険・支援情報の更新タイミングはいつですか？',
        question: '情報のメンテナンスはされていますか？',
        'answer' : <div className='leading-[250%]'>
            日々新たな情報を追加しています。<br />
            定期的に情報のメンテナンスも行っております。<br />
            <span
                className='
                    text-[10px]
                    md:text-[12px]
                '>
                ※情報の正確性、網羅性を完全に保証するものではございません。
                {/* ※ 情報の正確性を完全に保証するものではございません。 */}
            </span>
        </div>
    }
]

const Qa = () => {
    return (
        <section
            className={`
                ${Class.Section} px-2.5
                sm:px-5
                md:px-10
                lg:scroll-px-16
            `}>
            <div className='w-full'>
                <div
                    className='
                        flex flex-col items-center
                    '>
                    <Any
                        from={Vars.Animation.Title.from}
                        to={[
                            {
                                state: Vars.Animation.Title.to,
                                duration: Vars.Animation.Title.Duration,
                                easing: Vars.Animation.Title.Easing
                            }
                        ]}>
                        <h2
                            className='
                                text-base font-bold
                                sm:text-[22.5px]
                                md:text-3xl
                            '>
                            よくある質問
                        </h2>
                    </Any>

                    <div
                        className='
                            w-full max-w-fit relative
                        '>
                        <div className='qa__table'>
                            <table>
                                <tbody>
                                {
                                    QA.map((item, index) =>
                                        <tr key={index}>
                                            <Any
                                                as='th'
                                                from={Vars.Animation.Item.from}
                                                to={[
                                                    {
                                                        state: Vars.Animation.Item.to,
                                                        duration: Vars.Animation.Item.Duration,
                                                        easing: Vars.Animation.Item.Easing
                                                    }
                                                ]}>
                                                {item.question}
                                            </Any>
                                            <Any
                                                as='td'
                                                from={Vars.Animation.Item.from}
                                                to={[
                                                    {
                                                        state: Vars.Animation.Item.to,
                                                        duration: Vars.Animation.Item.Duration,
                                                        easing: Vars.Animation.Item.Easing
                                                    }
                                                ]}>
                                                <div>{item.answer}</div>
                                            </Any>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>

                        <Glow
                            size='10'
                            className={`
                                w-[300px] top-0 right-0 translate-x-[40%] -translate-y-[40%] opacity-70
                                sm:w-[500px] sm:opacity-80
                                md:w-[600px] md:-translate-y-[50%] md:opacity-100
                                lg:w-[700px]
                            `}>
                        </Glow>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Qa;