import React from 'react';
import Vars, { Class } from '@/constants/vars';
import PresentMobile from '@/assets/images/navi/recommend/recommend_m.png';
import PresentDesktop from '@/assets/images/navi/recommend/recommend_d.png';
import scrollTo from '@/utils/window/scroll';

const Recommend = () => {
    return (
        <section
            className={`
                ${Class.Section_Home} px-2.5
                md:px-5
                lg:px-10
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        group w-[77%] max-w-[338px] relative cursor-pointer
                        md:w-[65%] md:max-w-[634px]
                        lg:max-w-[930px]
                        transition duration-300 ease-outQuad
                        hover:scale-105
                    '
                    onClick={() => scrollTo(Vars.IDs.Navi_Plan_Banner)}>
                    <div
                        className='
                            w-full
                            md:hidden
                        '>
                        <img
                            src={PresentMobile}
                            alt='recommend'
                            sizes='100%'
                        />
                    </div>
                    <div
                        className='
                            w-full hidden
                            md:block
                        '>
                        <img
                            src={PresentDesktop}
                            alt='recommend'
                            sizes='100%'
                        />
                    </div>
                    <div
                        className='
                            absolute bottom-[5%] right-[7%]
                            md:bottom-[6%] md:right-[9%]
                        '>
                        <p
                            className='
                                text-[12px] font-bold tracking-[0.05em] underline underline-offset-2 transition-transform duration-[350ms]
                                md:text-[14px]
                                lg:text-[16px]
                                group-hover:translate-x-[10px]
                            '>
                            料金を見る→
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recommend;