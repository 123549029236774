import React, { Fragment, useState } from 'react';
import Vars, { Class } from '@/constants/vars';
import Interface1 from '@/assets/images/navi/search/inter_1.png';
import Interface2 from '@/assets/images/navi/search/inter_2.png';
import Interface3 from '@/assets/images/navi/search/inter_3.png';
import Interface4 from '@/assets/images/navi/search/inter_4.png';
import Interface5 from '@/assets/images/navi/search/inter_5.png';
import Interface6 from '@/assets/images/navi/search/inter_6.png';
import Interface7 from '@/assets/images/navi/search/inter_7.png';
import Interface8 from '@/assets/images/navi/search/inter_8.png';
import Interface from '@/components/element/navi/Interface';
import PlayImage from '@/assets/images/navi/search/play.svg';
import { Dialog, Transition } from '@headlessui/react';
import ReactPlayer from 'react-player';
import { _ } from '@/utils/tailwind/_';
import Any from '@/components/global/Any';

const Interfaces: {
    image: StaticImageData,
    title: React.ReactNode,
    description: React.ReactNode,
    imageClassName?: string,
}[] = [
    {
        image: Interface1,
        title: 'エリアで検索',
        description: <>
            都道府県、市町村単位まで選択して<br className='md:hidden'/>
            検索！
        </>,
        imageClassName: _`
            w-full max-w-[303px]
            md:max-w-[347px]
            lg:max-w-[391px]
        `
    },
    {
        image: Interface2,
        title: '利用目的で検索',
        description: <>
            関心ごと、状況にマッチした目的で<br className='md:hidden'/>
            検索！
        </>,
        imageClassName: _`
            w-full max-w-[315px]
            md:max-w-[362px]
            lg:max-w-[409px]
        `
    },
    {
        image: Interface3,
        title: 'キーワードで検索',
        description: '関連ワードでまとまった情報を検索！',
        imageClassName: _`
            w-full max-w-[218px]
            md:max-w-[276px]
            lg:max-w-[334px]
        `
    },
    {
        image: Interface4,
        title: '検索条件の保存',
        description: <>
            よく利用する検索条件を<br className='md:hidden'/>
            最大３つまで登録！
        </>,
        imageClassName: _`
            w-full max-w-[196px]
            md:max-w-[227px]
            lg:max-w-[258px]
        `
    },
    {
        image: Interface5,
        title: 'PDF出力',
        description: <>
            支援情報の詳細ページを<br className='md:hidden'/>
            ワンステップでPDF化！
        </>,
        imageClassName: _`
            w-full max-w-[157px]
            md:max-w-[221px]
            lg:max-w-[284px]
        `
    },
    {
        image: Interface6,
        title: '情報のリスト作成',
        description: <>
            保存しておきたい情報を<br className='md:hidden'/>
            フォルダで整理！
        </>,
        imageClassName: _`
            w-full max-w-[231px]
            md:max-w-[266px]
            lg:max-w-[300px]
        `
    },
    {
        image: Interface7,
        title: '閲覧履歴表示',
        description: '閲覧履歴が分かるので情報のウォッチに最適！',
        imageClassName: _`
            w-full max-w-[165px]
            md:max-w-[217px]
            lg:max-w-[268px]
        `
    },
    {
        image: Interface8,
        title: <>
            事業者向け情報の<br />
            お問い合わせフォーム
        </>,
        description: <div
            className='
                w-full flex flex-col items-center gap-y-[25px]
                md:items-start md:text-start md:gap-y-[18px]
            '>
            {/* 申請等について相談できる！<br /> */}
            情報の活用に向けた相談を無料受付中！<br />
            <span
                className='
                    text-[12px]
                '>
                ※ 補助金・助成金のご相談については何度で<br className='md:hidden'/>も無料で行えます。<br />
                ※ 申請のサポート等をご要望の場合、別途お<br className='md:hidden'/>見積りさせていただきます。
            </span>
        </div>,
        imageClassName: _`
            w-full max-w-[209px]
            md:max-w-[268px]
            lg:max-w-[327px]
        `
    },
]

const Search = () => {
    const [open, setOpen] = useState(false);

    return (
        <section
            className={Class.Section_Home}>
            <div
                className='
                    w-full max-w-[73rem] flex justify-center px-2.5 pb-[60px]
                    sm:px-5
                    md:px-10 md:pb-[67px]
                    lg:px-14 lg:pb-[74px]
                '>
                <div
                    className='
                        w-full max-w-[78rem] flex flex-col items-center gap-y-[60px]
                        md:gap-y-[75px]
                        lg:gap-y-[90px]
                    '>
                    <div
                        className='
                            w-full flex flex-col items-center text-center gap-y-[40px]
                            md:gap-y-[44px]
                            lg:gap-y-[48px]
                        '>
                        <p
                            className={`
                                text-[6.266vw] px-10 py-0.5 text-white leading-[1.4] text-center whitespace-nowrap bg-[#06B6D4]
                                sm:text-[25px]
                                md:text-[28px] md:px-12 md:py-1
                                lg:text-[32px] lg:px-14 lg:py-1.5
                            `}>
                            <span
                                className="
                                    font-bold leading-[1.4]
                                ">
                                便利な機能
                            </span>
                        </p>
                        <p
                            className='
                                text-[14px] font-medium leading-[175%]
                                md:text-[16px]
                                lg:text-[18px]
                            '>
                            補助金・助成金、各支援制度を直感的なUIで、<br className='md:hidden'/>
                            誰でも簡単検索できます。<br />
                            事業者向け補助金・助成金の<span className='font-bold'>無料相談</span>も<br className='md:hidden'/>
                            随時受付しております。
                            {/* 補助金・助成金、各支援制度を直感的なUIで、<br className='md:hidden' />
                            誰でも簡単検索できます。 */}
                        </p>
                    </div>
                    
                    <div
                        className='
                            w-full flex flex-col items-center gap-y-16
                            md:gap-y-24
                            lg:gap-y-36
                        '>
                        <div
                            className='
                                w-full max-w-[350px] flex flex-col items-center gap-y-[60px]
                                md:max-w-none md:gap-y-[90px]
                                lg:gap-y-[120px]
                            '>
                            {
                                Interfaces.map((_interface, index) =>
                                    <Interface
                                        key={index}
                                        index={index}
                                        _interface={_interface}
                                    />
                                )
                            }
                        </div>
                        <Any
                            className={`
                                flex gap-x-1 items-center text-[1rem] py-[0.25rem] cursor-default pl-[2rem] pr-[0.5rem] border border-[#D30D58] bg-[#D30D58] rounded-[50px] text-white duration-300
                                md:text-[1.125rem] md:pl-[2.5rem]
                                lg:text-[18px] lg:py-[1rem] lg:pr-[1rem] lg:rounded-[55px] lg:gap-x-[1.25rem]
                                hover:bg-white hover:text-[#D30D58]
                            `}
                            from='opacity-0 translate-y-[40px]'
                            to={[
                                {
                                    state: 'opacity-100 translate-y-0',
                                    duration: 1000,
                                    easing: Vars.Animation.Item.Easing,
                                }
                            ]}
                            onClick={() => setOpen(true)}>
                            <p>
                                動画でわかる！<br />
                                8LIFE NAVI
                            </p>
                            <div
                                className='
                                    w-[3rem] border border-[#D30D58] rounded-full
                                    md:w-[4rem]
                                    lg:w-[4rem]
                                '>
                                <img
                                    src={PlayImage}
                                    alt="play button"
                                    sizes='100%'
                                />
                            </div>
                        </Any>
                    </div>
                </div>
                <Transition
                    appear
                    show={open}
                    as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed top-0 left-0 w-screen h-screen bg-black/20 backdrop-blur-sm z-[51]"
                        onClose={() => setOpen(false)}
                        onClick={() => setOpen(false)}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100 "
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="fixed top-1/2 left-1/2  -translate-x-[50%] -translate-y-1/2 rounded-xl">
                            {
                                <div
                                    className={`
                                        w-screen max-w-[900px] relative aspect-video px-2.5
                                        md:px-6
                                    `}>
                                    <ReactPlayer
                                        playing
                                        controls
                                        url={'https://vimeo.com/929395747/8a1fcfdd00'}
                                        width={'100%'}
                                        height={'100%'}
                                    />

                                    <button
                                        className="absolute text-lg font-bold text-black -top-[2.5px] right-[30px] sm:text-[22.5px] md:text-3xl"
                                        onClick={() => setOpen(false)}>
                                            <div className="p-1 transition-colors duration-150 rounded-full bg-gray-50 hover:text-red-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[30px] h-w-[30px]">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                    </button>
                                </div>
                            }
                            </div>
                        </Transition.Child>
                    </Dialog>
                </Transition>
            </div>
        </section>
    );
}
 
export default Search;