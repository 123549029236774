import React from 'react';
import Any from '@/components/global/Any';
import Vars from '@/constants/vars';
import { twMerge } from 'tailwind-merge';
import TriangleLeft from '@/assets/images/navi/search/triangle_left.png';
import TriangleRight from '@/assets/images/navi/search/triangle_right.png';

type Props = {
    index: number,
    _interface: {
        image: StaticImageData,
        title: React.ReactNode,
        description: React.ReactNode,
        imageClassName?: string,
    }
}

const Interface = ({ index, _interface }: Props) => {
    const isOdd = index % 2 === 0;

    return (
        <div
            className={`
                w-full flex flex-col items-center gap-y-9
                md:justify-between md:gap-x-14
                lg:gap-x-20
                ${
                    isOdd ? 'md:flex-row' : 'md:flex-row-reverse'
                }
            `}>
            <Any
                className={
                    twMerge(
                        `
                            w-full max-w-[300px]
                            md:max-w-[350px]
                            lg:max-w-[400px]
                        `,
                        _interface.imageClassName
                    )
                }
                from='opacity-0 -translate-x-[20px]'
                to={[
                    {
                        state: 'opacity-100 translate-x-0',
                        duration: 1000,
                        easing: Vars.Animation.Item.Easing,
                        delay: index === 0 ? 250 : 0
                    }
                ]}>
                <img
                    src={_interface.image as any}
                    alt="ui"
                    sizes='100%'
                />
            </Any>
            <Any
                className='
                    w-full relative bg-white rounded-[10px]
                    md:w-fit md:rounded-[15px]
                    lg:rounded-[20px]
                '
                from='opacity-0 -translate-x-[20px]'
                to={[
                    {
                        state: 'opacity-100 translate-x-0',
                        duration: 1000,
                        easing: Vars.Animation.Item.Easing,
                        delay: Vars.Delay + (index === 0 ? 250 : 0)
                    }
                ]}>
                <div
                    className={`
                        absolute top-1/2 -translate-y-1/2 hidden
                        md:w-[20px] md:block
                        lg:w-[28px]
                        ${
                            isOdd ? 'right-[calc(100%-1px)]' : 'left-[calc(100%-1px)]'
                        }
                    `}>
                    <img
                        src={isOdd ? TriangleLeft : TriangleRight}
                        alt='arrow'
                        sizes='100%'
                    />
                </div>
                <div
                    className='
                        flex flex-col items-center gap-y-[1px] text-center rounded-[10px] py-[15px] px-2.5
                        md:items-start md:text-start md:gap-y-[3px] md:rounded-[15px] md:py-[23px] md:px-[20px]
                        lg:gap-y-[5px] lg:rounded-[20px] lg:py-[30px] lg:px-[30px]
                    '>
                    <p
                        className='
                            font-gothic text-[14px] tracking-[.2em] leading-[180%]
                            md:text-[15px]
                            lg:text-[16px]
                        '>
                        Function {index + 1}
                    </p>
                    <div
                        className='
                            flex flex-col items-center gap-y-7 text-center
                            md:text-start
                        '>
                        <h4
                            className='
                                w-full text-[25px] font-black
                                md:text-[31px]
                                lg:text-[38px]
                            '>
                            {_interface.title}
                        </h4>
                        <div
                            className='
                                w-full text-[14px]
                                md:text-[19px]
                                lg:text-[25px]
                            '>
                            {_interface.description}
                        </div>
                    </div>
                </div>
            </Any>
        </div>
    );
}

export default Interface;