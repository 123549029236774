import React from 'react';
import { Class } from '@/constants/vars';
import Tick from '@/assets/images/navi/solution/tick.png';
import Back from '@/assets/images/navi/solution/back.png';

const Solutions: React.ReactNode[] = [
    <>
        <span
            className='
                text-[#FFA800]
            '>
            日本最大級
        </span>
        の情報量
    </>,
    <>
        <span
            className='
                text-[#FFA800]
            '>
            個人・事業者向け
        </span><br />
        両方の情報を収録
    </>,
    <>
        全国
        <span
            className='
                text-[#FFA800]
            '>
            各地域
        </span>
        の情報を収録
    </>,
    <>
        <span
            className='
                text-[#FFA800]
            '>
            毎日
        </span>
        情報を追加
    </>,
    <>
        定期的な<br />
        <span
            className='
                text-[#FFA800]
            '>
            情報のメンテナンス
        </span>
    </>,
    <>
        制度の<br />
        <span className='text-[#FFA800]'>担当窓口、情報元</span>を掲載
    </>,
    <>
        <span className='text-[#FFA800]'>新着情報・オススメ情報</span><br />
        を定期的に配信
    </>,
    <>
        情報に<br />
        <span className='text-[#FFA800]'>閲覧時期</span>を表示
    </>,

]

const Solution = () => {
    return (
        <section
            className={Class.Section_Home}>
            <div
                className='
                    w-full relative flex justify-center max-w-[87rem] px-2.5
                    sm:px-5
                    md:px-10
                    lg:px-14
                '>
                <div
                    className='
                        w-full max-w-[400px] relative
                        md:max-w-none
                    '>
                    <div
                        className='
                            w-full h-full absolute top-0 left-0
                        '>
                        <img
                            src={Back}
                            alt='back'
                            className='w-full h-full object-fill'
                        />
                    </div>
                    <div
                        className='
                            w-full relative flex flex-col items-center gap-y-[64px] px-[35px] pt-[40px] pb-[77px]
                            sm:px-[50px]
                            md:gap-y-[75px] md:px-10 md:pt-[58px] md:pb-[76px]
                            lg:gap-y-[86px] lg:px-16 lg:pt-[77px] lg:pb-[75px]
                        '>
                        <p
                            className={`
                                text-[6.266vw] px-10 py-0.5 text-white leading-[1.4] text-center whitespace-nowrap bg-[#06B6D4]
                                sm:text-[25px]
                                md:text-[28px] md:px-12 md:py-1
                                lg:text-[32px] lg:px-14 lg:py-1.5
                            `}>
                            <span
                                className="
                                    font-bold leading-[1.4]
                                ">
                                特徴
                            </span>
                        </p>
                        <div
                            className='
                                w-full grid grid-cols-1 gap-y-[44px]
                                md:gap-y-[53px] md:gap-x-[22px] md:grid-cols-2
                                lg:grid-cols-3 lg:gap-y-[63px] lg:gap-x-[30px]
                            '>
                            {
                                Solutions.map((solution, index) =>
                                    <div
                                        key={index}
                                        className={`
                                            w-full h-[89px] relative box-content flex justify-center items-center border border-[#243133] rounded-[10px]
                                            md:[105px] md:rounded-[15px] md:py-[10px]
                                            lg:h-[130px] lg:rounded-[20px]
                                            ${
                                                index >= Solutions.length - 2 && `
                                                    lg:translate-x-[calc(50%+15px)]
                                                `
                                            }
                                        `}>
                                        <p
                                            className='
                                                text-[14px] text-center
                                                md:text-[19px]
                                                lg:text-[1.955vw]
                                                xl:text-[25px]
                                            '>
                                            {solution}
                                        </p>
                                        <div
                                            className='
                                                w-[30px] absolute top-0 left-1/2 -translate-x-1/3 -translate-y-1/2
                                                md:w-[40px]
                                                lg:w-[50px]
                                            '>
                                            <img
                                                src={Tick}
                                                alt='tick'
                                                sizes='100%'
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Solution;