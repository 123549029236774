import React from "react";
import Layout from "../../components/layouts/layout";
import { graphql } from "gatsby";
import Seo from "@/components/Seo";

export const SingleData = graphql`
    query SinglePost($slug: String) {
        sanityPost(slug: {current: {eq: $slug}}) {
            title
        }
    }
`;

const BlogPost = ({ data }) => {
  const post = data.sanityPost;
    const { title } = post;
    // const Image = post.mainImage.asset.url;
    // console.log(data);
    
    return (
        <Layout>
            <section id="content">
                <div className="default__width">
                <div className="content">
                    {/* <div className="content--img"><img src={Image} alt={title}/></div> */}
                    <h2 className="content--title">{title}</h2>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default BlogPost;

export const Head = () => (
    <Seo
        title="Blog | 8LIFE"
    />
);