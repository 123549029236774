import React from 'react';
import Seo from "@/components/Seo";
import Layout from '@/components/layouts/layout';
import Hero from '@/components/template/navi/Hero';
import Process from '@/components/template/navi/Process';
// import Smartphone from '@/components/template/navi/Smartphone';
import Solution from '@/components/template/navi/Solution';
import Published from '@/components/template/navi/Published';
// import Information from '@/components/template/navi/Information';
import Search from '@/components/template/navi/Search';
import LearnMore from '@/components/template/navi/LearnMore';
import Plan from '@/components/template/navi/Plan';
// import Note from '@/components/template/navi/Note';
import FAQ from '@/components/template/navi/FAQ';
import SpaceBottom from '@/components/template/global/SpaceBottom';
import NoteTop from '@/components/template/navi/NoteTop';
import Subsidy from '@/components/template/navi/Subsidy';
import Media from '@/components/global/Media';
import NoteBottom from '@/components/template/navi/NoteBottom';
import Background from '@/components/global/Background';
import Recommend from '@/components/template/navi/Recommend';
import Free from '@/components/template/navi/Free';
import SalesCard from '@/components/template/navi/SalesCard';

const IndexPage = () => {
    return (
        <Layout
            className='
                bg-[#F7FAFB]
            '
            // headerClassName='
            //     border-b-[#FFA800]
            // '
            // consultClassName='
            //     text-[#FFA800]
            //     before:bg-[#FFA800]
            //     after:border after:border-[#FFA800] after:bg-white
            // '
            toId='note-bottom'>
            <Background />
            <div
                className='
                    w-full relative z-[1]
                '>
                <Hero />
                <Free />
                <Process />
                <Recommend />
                <NoteTop />
                {/* <Smartphone /> */}
                <Subsidy />
                <Published />
                <Solution />
                {/*
                // <Published />
                <Information />
                
                <Note />
                    */}
                <Search />
                <Plan />
                <NoteBottom />
                <SalesCard />
                <FAQ />
                <LearnMore />
                <Media
                    className='
                        mt-28
                        sm:mt-28
                        md:mt-32
                        lg:mt-40
                    '
                />
                <SpaceBottom />
            </div>
        </Layout>
    );
}

export default IndexPage;

export const Head = () => (
    <Seo
        title="NAVI[ナビ]プラン | 8LIFE[エイトライフ]"
        description='個人・事業者向けの補助金・助成金などの様々な支援情報が手軽に得られるよう作られたプラン。どんな方でも使いやすく、役立つ公的支援がきっと見つかります。'
        pathname={undefined}
        children={undefined} 
    />
);