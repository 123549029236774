import React from 'react'
import Card from './Card';
import Any from '@/components/global/Any';

const CardList = ({ firstDelay }: { firstDelay: number }) => {
    return (
        <div
            className='
                w-full flex flex-col items-center gap-y-8 px-2.5 py-5 bg-[#F0FBFC] rounded-bl-md
                sm:py-10
                md:gap-y-7 md:px-5 md:py-10 md:pt-[110px]
                lg:gap-y-6 lg:px-7 lg:pb-10
            '>
            {
                (['navi', 'sales'] as CardType[]).map((type, index) =>
                    <Any
                        key={index}
                        className='
                            w-full flex justify-center
                        '
                        instant={firstDelay > 0}
                        from='translate-x-[-20px] opacity-0'
                        to={[
                            {
                                state: 'translate-x-0 opacity-100',
                                duration: 1000,
                                easing: 'outQuad',
                                delay: firstDelay + 250 * index
                            }
                        ]}>
                        <Card
                            key={index}
                            type={type}
                        />
                    </Any>
                )
            }
        </div>
    )
}

export default CardList;