import React from 'react';
import BackgroundAbstract from '@/assets/images/global/background.jpg';

const Background = () => {
    return (
        <div
            className='
                w-full h-full fixed top-0 left-0
            '>
            <img
                src={BackgroundAbstract}
                alt="background"
                className='
                    w-full h-full object-cover
                '
            />
        </div>
    );
}

export default Background;