import Any from '@/components/global/Any';
import ContactButton from '@/components/global/ContactButton';
import Glow from '@/components/global/Glow';
import Vars, { Class } from '@/constants/vars';
import CheckDesktop from '@/images/download/check_d.png';
import CheckMobile from '@/images/download/check_m.png';
import Pointer from '@/assets/images/sales/download/pointer.png';
import React from 'react';

const Download = () => {
    return (
        <section
            className={`
                ${Class.Section}
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full relative flex justify-center bg-[#E3FBFF]
                    '>
                    <div
                        className='
                            w-full max-w-[420px] relative flex flex-col items-center space-y-6 py-6 px-1.5
                            sm:space-y-9 sm:py-9
                            md:max-w-[800px] md:space-y-12 md:py-10
                            lg:max-w-[900px] lg:space-y-[60px] lg:py-12
                        '>
                        <div
                            className='
                                relative w-full
                                md:w-[90%]
                            '>
                            <img
                                src={CheckDesktop}
                                alt='image'
                                sizes='100%'
                                className='hidden md:inline'
                            />
                            <img
                                src={CheckMobile}
                                alt='image'
                                sizes='100%'
                                className='md:hidden'
                            />
                        </div>
                        <div
                            className='
                                absolute w-[115px] top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2
                                sm:-translate-y-1/3
                                md:w-[126px] md:bottom-0 md:left-0 md:translate-x-[5%] md:-translate-y-[55px]
                                lg:w-[165px] lg:-translate-x-[15%] lg:-translate-y-[63px]
                            '>
                            <img
                                src={Pointer}
                                alt='pointer'
                                sizes='100%'
                            />
                        </div>
                        <div
                            className='
                                w-full px-6 flex flex-col items-center
                            '>
                            <div
                                className='
                                    h-[100px]
                                    sm:h-[120px]
                                    md:hidden
                                '>
                            </div>
                            <Any
                                className='w-full max-w-[360px]'
                                from={Vars.Animation.Button.from}
                                to={[
                                    {
                                        state: Vars.Animation.Button.to,
                                        duration: Vars.Animation.Button.Duration,
                                        easing: Vars.Animation.Button.Easing
                                    }
                                ]}>
                                <ContactButton
                                    className='
                                        text-[1rem]
                                        md:text-[1.125rem]
                                        lg:text-[18px] lg:py-[1.25rem] lg:rounded-[0.375rem]
                                    '
                                />
                                {/* <a
                                    target="_blank"
                                    href="/contact"
                                    rel="noreferrer"
                                    className='
                                        w-full text-base text-white font-bold transition-colors duration-200 bg-[#D30D58] px-1 py-3 rounded-md centralize border border-[#D30D58]
                                        md:py-5  md:text-lg
                                        hover:text-[#D30D58] hover:bg-white
                                    '>
                                    <p>お問い合わせ</p>
                                </a> */}
                            </Any>
                        </div>
                    </div>

                    <Glow
                        size='20'
                        className={`
                            w-[500px] top-0 left-0 -translate-x-[50%] -translate-y-[40%] opacity-30
                            sm:w-[600px] sm:opacity-40
                            md:w-[800px] md:opacity-60
                            lg:w-[1000px] lg:opacity-70
                        `}
                        >
                    </Glow>
                </div>
            </div>
        </section>
    );
}

export default Download;