import React from 'react';
import check1 from '@/assets/images/sales/check1.svg';
import check2 from '@/assets/images/sales/check2.svg';

const PlanDescription = ({ description, no }: { description: PlanType['descriptions'][0], no: number }) => {
    const src = no === 0 ? check1 : check2;

    return (
        <div
            className='
                flex items-center space-x-6 whitespace-nowrap
            '>
            <img
                alt='image'
                src={src}
                className='w-[15px] h-[15px]'
            />
            <div
                className='
                    text-[12.5px]
                    md:text-base
                '>
                {description}
            </div>
        </div>
    );
}

export default PlanDescription;