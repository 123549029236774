import React from 'react';
import TickMobile from '@/assets/images/sales/tick/tick_m.png';
import TickDesktop from '@/assets/images/sales/tick/tick_d.png';
import Stars from '@/assets/images/sales/global/stars.svg';
import Glow from '@/components/global/Glow';
import Vars from '@/constants/vars';
import Any from '@/components/global/Any';

function Ticks() {
    return (
        <section
            className={`
                mt-12 flex justify-center px-3
                sm:mt-14 sm:px-6
                md:mt-20 md:px-12
                lg:px-20
            `}>
            <div
                className='
                    w-full max-w-[1250px] relative flex flex-col items-center
                '>
                <div
                    className='
                        w-full relative flex justify-center border border-[#D30D58]/50 rounded-lg px-3 py-12
                        sm:px-6 sm:py-14
                        md:px-12 md:py-20
                    '>
                    <div
                        className='
                            w-full max-w-[360px] flex flex-col items-center
                            md:max-w-[800px] md:space-y-12
                            lg:max-w-[900px]
                        '>
                        <Any
                            from={Vars.Animation.Title.from}
                            to={[
                                {
                                    state: Vars.Animation.Title.to,
                                    duration: Vars.Animation.Title.Duration,
                                    easing: Vars.Animation.Title.Easing
                                }
                            ]}>
                            <p
                                className="
                                    text-lg text-center font-bold bg-white
                                    sm:text-[22.5px]
                                    md:text-3xl
                                ">
                                <span
                                    className='
                                        text-[22.5px] text-[#06B6D4]
                                        sm:text-3xl
                                        md:text-4xl
                                    '>
                                    8LIFE
                                </span>
                                <span>なら効果的に課題を</span>
                                <span
                                    className='
                                        relative text-[22.5px] text-[#D30D58] whitespace-nowrap
                                        sm:text-3xl
                                        md:text-4xl
                                    '>
                                    <span
                                        className='
                                            w-4 absolute bottom-full left-full -translate-x-1/2 select-none
                                            sm:w-5
                                            md:w-[22.5px]
                                            lg:w-6
                                        '>
                                        <img
                                            src={Stars}
                                            alt='star'
                                            sizes='100%'
                                        />
                                    </span>
                                    <span>解決</span>
                                </span>
                                <span>できます!</span>
                            </p>
                        </Any>

                        <Any
                            className="
                                relative w-full flex items-center justify-center select-none pointer-events-none
                            "
                            from={Vars.Animation.Image.from}
                            to={[
                                {
                                    state: Vars.Animation.Image.to,
                                    duration: Vars.Animation.Image.Duration,
                                    easing: Vars.Animation.Image.Easing
                                }
                            ]}>
                            <img
                                src={TickMobile}
                                alt='tick-mobile'
                                sizes='100%'
                                className='md:hidden'
                            />
                            <img
                                src={TickDesktop}
                                alt='tick-desktop'
                                sizes='100%'
                                className='hidden md:inline'
                            />

                            <Glow
                                size='10'
                                className='
                                    w-[500px] bottom-0 left-1/2 translate-y-[40%] -translate-x-1/2 opacity-70
                                    sm:w-[800px]
                                    md:w-[500px] md:left-0 md:-translate-x-[50%] md:opacity-100
                                '>
                            </Glow>
                        </Any>
                    </div>

                    <div
                        className='
                            w-[70px] aspect-square absolute flex justify-center items-center rounded-full bg-[#D30D58] top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 border-4 border-white
                            sm:w-[90px]
                            md:w-[100px]
                            lg:w-[120px]
                        '>
                        <p
                            className='
                                text-white text-base
                                sm:text-lg
                                md:text-[22.5px]
                            '>
                            でも
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Ticks;