import React, { useEffect } from 'react';
import Seo from "@/components/Seo";
import Layout from '@/components/layouts/layout';
import Hero from '@/components/template/home/Hero';
import GroupCompany from '@/components/GroupCompany';
import Note from '@/components/template/home/Note';
import Plan from '@/components/template/home/Plan';
import CardList from '@/components/element/home/hero/CardList';
import { Class } from '@/constants/vars';
import SpaceBottom from '@/components/template/global/SpaceBottom';
import Media from '@/components/global/Media';
import scrollTo from '@/utils/window/scroll';

const ToId = 'plan';

const IndexPage = () => {
    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('return') !== null) {
            scrollTo(ToId);
        }
    }, []);

    return (
        <Layout
            fromTop
            loginClassName='
                after:bg-[#06B6D4]
                hover:text-[#06B6D4]
            '
            footerClassName='
                bg-[#06B6D4]
            '
            toId={ToId}>
            <Hero />
            <GroupCompany />
            <div
                className={`
                    w-full ${Class.Section_Home}
                    md:hidden
                `}>
                <CardList
                    firstDelay={0}
                />
            </div>
            <Plan />
            <Note />
            <Media 
                className='
                    mt-20
                    sm:mt-20
                    md:mt-24
                    lg:mt-32
                '    
            />
            <SpaceBottom />
        </Layout>
    );
}

// 8LIFE[エイトライフ]は、公的保険・公的支援の情報検索サービス。補助金・助成金も含め、全国2,000箇所・12万件以上の情報を集約しています。

export default IndexPage;

export const Head = () => (
    <Seo
        title="8LIFE[エイトライフ] | 公的支援プラットフォーム"
        description='8LIFE[エイトライフ]は、補助金・助成金・公的保険など様々な公的支援制度の検索や申請相談が行えるサービス。全国約2000機関・12万件以上の情報を集約しています。'
        pathname={undefined}
        children={undefined} 
    />
);