import React from 'react';
import FlowItem from '@/components/element/sales/FlowItem';
import FlowDesktop from '@/assets/images/sales/flow/flow_d.png';
import Call from '@/assets/images/sales/flow/call.png';
import Price from '@/assets/images/sales/flow/price.png';
import Opps from '@/assets/images/sales/global/opps.svg';
import Glow from '@/components/global/Glow';
import Vars, { Class as VarClass } from '@/constants/vars';
import Any from '@/components/global/Any';
import { twMerge } from 'tailwind-merge';

const Class = {
    Glow: `
        w-[500px] opacity-80
        sm:opacity-90
        md:w-[800px] md:opacity-100
        lg:w-[1000px]
    `
}

const FlowPoints: FlowSales[] = [
    {
        title: '公的保険制度',
        points: [
            {
                icon: require('@/assets/images/sales/flow/ins/ins_1.png').default,
                description: '公的医療保険'
            },
            {
                icon: require('@/assets/images/sales/flow/ins/ins_2.png').default,
                description: '労災保険'
            },
            {
                icon: require('@/assets/images/sales/flow/ins/ins_3.png').default,
                description: '雇用保険'
            },
            {
                icon: require('@/assets/images/sales/flow/ins/ins_4.png').default,
                description: '自立支援医療'
            },
            {
                icon: require('@/assets/images/sales/flow/ins/ins_5.png').default,
                description: <>
                    障害福祉<br />
                    サービス
                </>
            },
            {
                icon: require('@/assets/images/sales/flow/ins/ins_6.png').default,
                description: <>
                    公的年金<br />
                    公的介護保険
                </>
            },
        ],
    },
    {
        title: '個人向け支援情報',
        points: [
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_1.png').default,
                description: '就業'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_2.png').default,
                description: '結婚'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_3.png').default,
                description: '出産'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_4.png').default,
                description: '子育て'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_5.png').default,
                description: <>
                    住宅・<br />
                    リフォーム
                </>
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_6.png').default,
                description: 'シニア'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_7.png').default,
                description: '災害'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_8.png').default,
                // description: '重病'
                description: '病気'
            },
            {
                icon: require('@/assets/images/sales/flow/ins_sup/ins_sup_9.png').default,
                description: '事故'
            }
        ]
    },
    {
        title: '法人向け支援情報',
        points: [
            {
                icon: require('@/assets/images/sales/flow/life/life_1.png').default,
                description: '補助金'
            },
            {
                icon: require('@/assets/images/sales/flow/life/life_2.png').default,
                description: '助成金'
            },
            {
                icon: require('@/assets/images/sales/flow/life/life_3.png').default,
                description: '融資'
            },
            {
                icon: require('@/assets/images/sales/flow/life/life_4.png').default,
                description: '税制'
            }
        ]
    }
];

const Desc = ({className}: { className?: string }) => {
    return (
        <p
            className={twMerge(`
                absolute top-[46px] left-1/2 -translate-x-1/2 text-[12px] font-bold text-center leading-[175%] whitespace-nowrap
                md:text-[15px] md:-translate-x-[40%]
                lg:text-[18px]
            `, className)}>
            事業者向け補助金・助成金
            <span className='font-normal'>の</span>
            <span className='text-[#D30D58]'>無料相談<br className='md:hidden'/></span>
            <span className='font-normal'>も随時受付中！</span>
        </p>
    )
}

const Flows = () => {
    return (
        <section
            className={`
                ${VarClass.Section} px-3
                sm:px-6
                md:px-12
                lg:px-20
            `}>
            <div
                className='
                    w-full max-w-[1250px] flex justify-center
                '>
                <div
                    className='
                        w-full flex flex-col items-center space-y-6
                        sm:space-y-10 md:space-y-12
                    '>
                    <Any
                        from={Vars.Animation.Title.from}
                        to={[
                            {
                                state: Vars.Animation.Title.to,
                                duration: Vars.Animation.Title.Duration,
                                easing: Vars.Animation.Title.Easing
                            }
                        ]}>
                        <p
                            className="text-lg font-bold sm:text-[22.5px] md:text-3xl">
                            <span
                                className='
                                    text-[22.5px] text-[#D30D58]
                                    sm:text-3xl
                                    md:text-4xl
                                '>
                                8LIFE
                            </span>
                            <span
                                className='relative whitespace-nowrap'>
                                <span
                                    className='absolute select-none w-4 bottom-full left-full sm:w-5 md:w-[22.5px] lg:w-6'>
                                    <img
                                        src={Opps}
                                        alt='opps'
                                        sizes='100%'
                                    />
                                </span>
                                <span>の掲載データ</span>
                            </span>
                        </p>
                    </Any>
                    <div
                        className='
                            w-full
                        '>
                        <div
                            className='
                                w-full relative flex justify-center z-10 translate-y-[6.5%]
                                md:translate-y-[7.5%]
                            '>
                            <div
                                className='
                                    w-full max-w-[276px] select-none pointer-events-none
                                    md:max-w-[450px] md:mr-[20%]
                                    lg:max-w-[624px]
                                '>
                                <div
                                    className='
                                        w-full flex flex-col items-center gap-y-[60px]
                                        md:hidden
                                    '>
                                    <div
                                        className='
                                            w-full
                                        '>
                                        <img
                                            src={Price}
                                            alt='price'
                                            sizes='100%'
                                        />
                                    </div>
                                    <Desc
                                        className='
                                            top-[61px]
                                        '
                                    />
                                    <div
                                        className='
                                            w-[36%] max-w-[100px]
                                        '>
                                        <img
                                            src={Call}
                                            alt='call'
                                            sizes='100%'
                                        />
                                    </div>
                                </div>
                                <div
                                    className='
                                        w-full hidden
                                        md:block
                                    '>
                                    <img
                                        src={FlowDesktop}
                                        alt='flow-desktop'
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                            <Desc
                                className='
                                    hidden top-[37%]
                                    md:block
                                '
                            />
                        </div>
                        <div
                            className="
                                w-full relative flex flex-col items-center gap-y-6
                                sm:gap-y-12
                                md:gap-y-[70px]
                            ">
                            {
                                FlowPoints.map((flow, index) =>
                                    <FlowItem
                                        key={index}
                                        flow={flow}
                                        no={index}
                                    />
                                )
                            }

                            <Glow
                                size='10'
                                className={`
                                    ${Class.Glow} top-0 right-0 translate-x-[40%] -translate-y-[40%]
                                    md:-translate-y-[50%]
                                `}>
                            </Glow>

                            <Glow
                                size='10'
                                className={`
                                    ${Class.Glow} bottom-0 left-0 -translate-x-[50%] translate-y-[40%]
                                    md:translate-y-[60%]
                                `}>
                            </Glow>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Flows;