import PlanItem from '@/components/element/sales/PlanItem';
import Any from '@/components/global/Any';
import NotePart from '@/components/global/NotePart';
import Sticker from '@/components/global/Sticker';
import Vars, { Class } from '@/constants/vars';
import React from 'react';

const PlanPoints: PlanType[] = [
    {
        title: <p className='text-[#3866AB]'>月間利用</p>,
        money: <p className='text-[#3866AB] leading-[1]'>1,980</p>,
        descriptions: [
            <div>
                <p>月ごとの支払いなので</p>
                <p>手軽に使い始められる</p>
            </div>,
            <p>1ユーザーから利用可能</p>
        ],
        duration: '/月（税込）',
        note: '※ 月間利用はIT導入補助金の対象外です。'
    },
    {
        title: <p className='text-[#D30D58]'>年間利用</p>,
        money: <p className='text-[#D30D58] leading-[1]'>19,800</p>,
        descriptions: [
            <div
                className='
                    flex flex-col gap-y-0.5
                    md:gap-y-1
                    lg:gap-y-1.5
                '>
                <p>月間利用に比べ</p>
                <p
                    className='
                        text-[#D30D58] text-2xl font-bold
                        md:text-3xl
                    '>
                    2ヶ月割引
                </p>
            </div>,
            <p>1ユーザーから利用可能</p>
        ],
        duration: '/年（税込）',
        note: '※ IT導入補助金を活用する場合は、税込23,760円/年（2ヶ月割引を適用しない金額）となり、そのうち最大2分の1がIT導入補助金で補助されます。'
    }
];

const Plan = () => {
    return (
        <section className={Class.Section}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full bg-[#E2FAFE] py-6 space-y-6
                        sm:space-y-10 sm:py-10
                        md:space-y-12 md:py-12
                        lg:py-[60px]
                    '>
                    <div
                        className='
                            flex flex-col items-center text-center space-y-9
                        '>
                        <Any
                            className='
                                text-[18px] flex flex-col items-center gap-y-3
                                md:text-[24px] md:gap-y-5
                                lg:text-[31px] lg:gap-y-6
                            '
                            from={Vars.Animation.Title.from}
                            to={[
                                {
                                    state: Vars.Animation.Title.to,
                                    duration: Vars.Animation.Title.Duration,
                                    easing: Vars.Animation.Title.Easing
                                }
                            ]}>
                            <div
                                className="
                                    flex justify-center 
                                ">
                                <Sticker
                                    type='sales'
                                    size='large'
                                    buttonClassName='
                                        px-8
                                        md:px-11
                                        lg:px-14
                                    '
                                />
                            </div>
                            <h2
                                className='
                                    font-bold
                                '>
                                利用料金
                            </h2>
                        </Any>
                        <Any
                            as='span'
                            className='text-[12.5px] md:text-base'
                            from={Vars.Animation.Description.from}
                            to={[
                                {
                                    state: Vars.Animation.Description.to,
                                    duration: Vars.Animation.Description.Duration,
                                    easing: Vars.Animation.Description.Easing
                                }
                            ]}>
                            手軽に使える月間利用とおトクな年間利用を<br className='md:hidden'/>
                            ご用意しております。
                        </Any>
                    </div>

                    <div
                        className='
                            w-full flex flex-col items-center space-y-6
                            sm:space-y-7
                            md:space-y-10
                            lg:space-y-12
                        '>
                        <div
                            className={`
                                w-full grid grid-cols-1 gap-6 px-3
                                sm:gap-10 sm:px-6
                                md:grid-cols-2 md:gap-12
                                lg:gap-12
                            `}>
                            {
                                PlanPoints.map((plan, index) =>
                                    <Any
                                        key={index}
                                        className={`
                                            w-full flex justify-center
                                            ${
                                                index % 2 === 0 ? 'md:justify-end' : 'md:justify-start'
                                            }
                                        `}
                                        from={Vars.Animation.Item.from}
                                        to={[
                                            {
                                                state: Vars.Animation.Item.to,
                                                duration: Vars.Animation.Item.Duration,
                                                easing: Vars.Animation.Item.Easing,
                                                delay: index * (Vars.Animation.Item.Delay || 0)
                                            }
                                        ]}>
                                        <PlanItem
                                            key={index}
                                            plan={plan}
                                            no={index}
                                        />
                                    </Any>
                                )
                            }
                        </div>
                        {/* <div
                            className='
                                w-full flex justify-center px-3
                                sm:px-6
                            '>
                            <div
                                className='
                                    w-full max-w-[320px] flex flex-col items-center bg-white pt-[22px] pb-[55px] gap-y-[26px] px-2 rounded-[0.5rem]
                                    md:max-w-[419px] md:pt-[32px] md:gap-y-[27px] md:px-4
                                    lg:max-w-[488px] lg:pt-[42px] lg:gap-y-[28px]
                                '>
                                <div
                                    className='
                                        text-[16px] text-center font-noto-sans-jp
                                        md:text-[19px]
                                        lg:text-[22px]
                                    '>
                                    <p
                                        className='
                                            font-medium text-black
                                        '>
                                        年間利用
                                    </p>
                                    <p
                                        className='
                                            font-bold text-danger
                                        '>
                                        【IT導入補助金を活用する場合】
                                    </p>
                                </div>
                                <div
                                    className='
                                        text-center flex justify-center flex-nowrap whitespace-nowrap gap-x-0.5
                                        sm:gap-x-1
                                    '>
                                    <p
                                        className={`
                                            text-[12.5px] font-medium self-start
                                            md:text-[1rem]
                                        `}>
                                        ¥
                                    </p>
                                    
                                    <div
                                        className='
                                            text-[45px] leading-[1]
                                            md:text-[5.865vw]
                                            lg:text-[60px]
                                        '>
                                        23,760
                                    </div>

                                    <p
                                        className={`
                                            text-[12.5px] font-medium self-end mb-1
                                            md:text-[1rem]
                                            sm:mb-[7.5px]
                                            md:mb-[10px]
                                            lg:mb-3
                                        `}>
                                        /年（税込）
                                    </p>
                                </div>
                                <div
                                    className='
                                        flex flex-col gap-y-[20px]
                                        md:gap-y-[23px]
                                        lg:gap-y-[26px]
                                    '>
                                    <p
                                        className='
                                            text-[16px] text-center text-danger font-bold
                                            md:text-[19px]
                                            lg:text-[22px]
                                        '>
                                        こちらの金額の最大1/2が<br />
                                        補助されます
                                    </p>
                                    <p
                                        className='
                                            text-[10px]
                                        '>
                                        ※ IT導入補助金は月間利用ではご活用いただけません。
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <NotePart />
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Plan;