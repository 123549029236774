
import React from 'react';
import Swipper from '@/components/global/Swipper';
import Coin from '@/assets/images/navi/process/coin.png';
import Agent from '@/assets/images/navi/process/agent.png';
import Graph from '@/assets/images/navi/process/graph.png';
import Building from '@/assets/images/navi/process/building.png';
import Vars from '@/constants/vars';

const Process1: string[] = [
    '省エネ補助金',
    '人材開発支援助成金',
    'ものづくり補助金',
    'キャリアアップ助成金',
    'IT導入補助金',
    '小規模事業者持続化補助金',
    '両立支援助成金',
    '事業再構築補助金',
    '雇用調整助成金',
    '中小企業投資省力化補助金',
    '働き方改革推進支援助成金',
    '事業承継補助金',
    '継続支援金',
    'トライアル雇用助成金',
    '臨時給付金'
];

const Process2: string[] = [
    '設備導入',
    '販路拡大',
    '人材確保',
    '省エネ',
    '海外展開',
    'デジタル化',
    '職場環境',
    'リスキリング',
    '製品開発',
    '教育訓練',
    'サービス開発',
    'カーボンニュートラル',
    '企業誘致',
    '両立支援',
    '事業承継',
    '知的財産',
    '起業創業',
    '防災',
    '復興'
];

const Process2Icon = [
    Agent,
    Graph,
    Building
];

const Process = () => {
    return (
        <section
            className="
                mt-[87px] flex justify-center
                md:mt-[81px]
                lg:mt-[76px]
            ">
            <div
                className='
                    w-full max-w-[87rem] flex justify-center
                '>
                <div
                    className='
                        w-full flex flex-col items-center gap-y-[45px]
                        md:gap-y-[62px]
                        lg:gap-y-[79px]
                    '>
                    <Swipper
                        duration={Vars.Process_Multiplier * 48000}>
                        {
                            Process1.map((process, index) =>
                                <div
                                    key={index}
                                    className='
                                        flex-shrink-0 flex items-center gap-x-[20px] mr-[30px]
                                        md:gap-x-[27px] md:mr-[40px]
                                        lg:gap-x-[35px] lg:mr-[50px]
                                    '>
                                    <div
                                        className='
                                            w-[18px]
                                            md:w-[22px]
                                            lg:gap-[27px]
                                        '>
                                        <img
                                            src={Coin}
                                            alt='coin'
                                            sizes='100%'
                                        />
                                    </div>
                                    <p
                                        className='
                                            text-[14px]
                                            md:text-[16px]
                                            lg:text-[18px]
                                        '>
                                        {process}
                                    </p>
                                </div>
                            )
                        }
                    </Swipper>
                    <Swipper
                        duration={Vars.Process_Multiplier * 57600}
                        reverse>
                        {
                            Process2.map((process, index) =>
                                <div
                                    key={index}
                                    className='
                                        flex-shrink-0 flex items-center gap-x-[30px] mr-[30px]
                                        md:gap-x-[41px] md:mr-[41px]
                                        lg:gap-x-[52px] lg:mr-[52px]
                                    '>
                                    <div
                                        className='
                                            h-[17px]
                                            md:h-[24px]
                                            lg:h-[31px]
                                        '>
                                        <img
                                            src={Process2Icon[(index === Process2.length - 1) ? 1 :  index % 3]}
                                            alt='icon'
                                            className='h-full w-auto'
                                        />
                                    </div>
                                    <p
                                        className='
                                            text-[14px] font-bold text-[#06B6D4] leading-[1]
                                            md:text-[19px]
                                            lg:text-[24px]
                                        '>
                                        {process}
                                    </p>
                                </div>
                            )
                        }
                    </Swipper>
                </div>
            </div>
        </section>
    );
}
 
export default Process;