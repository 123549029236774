import React from "react"
import { UseSiteMetadata } from "../hooks/Use-site-metadata";
import Og from "@/assets/images/og.webp";

type Props = {
    title?: string
    description?: string,
    pathname?: string,
    children?: React.ReactNode
}

const Seo = ({
    title,
    description,
    pathname,
    children
}: Props) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl } = UseSiteMetadata()
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
    }

    return (
        <>
        <title>{seo.title}</title>
        <meta name="url" content={seo.url} />
        <meta name="card" content="summary_large_image" />
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={siteUrl + Og} />
        <meta name="image:type" content='image/webp' />
        <meta name="image:width" content='1200' />
        <meta name="image:height" content='630' />

        <meta property="og:url" content={seo.url} />
        <meta name="og:title" content={seo.title} />
        <meta name="og:description" content={seo.description} />
        <meta name="og:image" content={siteUrl + Og} />
        <meta name="og:image:type" content='image/webp' />
        <meta name="og:image:width" content='1200' />
        <meta name="og:image:height" content='630' />

        <meta name="twitter:card" content='summary_large_image' />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={siteUrl + Og} />
        <meta name="twitter:image:type" content='image/webp' />
        <meta name="twitter:image:width" content='1200' />
        <meta name="twitter:image:height" content='630' />

        {children}
        </>
    );
}

export default Seo;