import Any from '@/components/global/Any';
import Vars from '@/constants/vars';
import React from 'react';
import Plan1 from '@/assets/images/navi/plan/plan_1.png';
import Plan2 from '@/assets/images/navi/plan/plan_2.png';
import Sticker from '@/components/global/Sticker';
import NotePart from '@/components/global/NotePart';
import DiscountDesktop from '@/assets/images/navi/plan/discount_d.png';
import DiscountMobile from '@/assets/images/navi/plan/discount_m.png';

const Plan = () => {
    return (
        <section
            className={`
                mt-28 flex justify-center
                md:mt-24
                lg:mt-32
            `}>
            <div
                className='
                    w-full relative flex justify-center bg-[#E2FCFE]
                '>
                <div
                    className='
                        w-full relative max-w-[87rem] flex justify-center
                    '>
                    <div
                        className='
                            absolute max-w-full top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden
                            md:left-0 md:translate-x-1/4
                        '>
                        <Any
                            className='px-2.5'
                            from='translate-x-[-20px] opacity-0'
                            to={[
                                {
                                    state: 'translate-x-0 opacity-100',
                                    duration: 1000,
                                    easing: 'outQuad',
                                }
                            ]}>
                            <Sticker type='navi' size='wise' />
                        </Any>
                    </div>
                    <div
                        className='
                            w-full relative  flex flex-col items-center px-2.5 py-6 gap-y-6 pt-[50px]
                            sm:gap-y-10 sm:py-10 sm:px-5
                            md:gap-y-12 md:py-12 md:px-10 md:pt-[60px]
                            lg:py-[60px] lg:px-14 lg:pt-[80px]
                        '>
                        <Any
                            className='
                                text-center flex flex-col items-center gap-y-[28px]
                                md:ga-y-[25px]
                                lg:gap-y-[21px]
                            '
                            from='opacity-0 translate-y-[40px]'
                            to={[
                                {
                                    state: 'opacity-100 translate-y-0',
                                    duration: 1000,
                                    easing: 'outQuart'
                                }
                            ]}>
                            <h2
                                className='
                                    text-[22px] font-bold font-noto-sans-jp flex flex-col items-center
                                    md:text-[30px]
                                    lg:text-[39px]
                                '>
                                利用料金
                            </h2>
                            <p
                                className='
                                    text-[14px]
                                    md:text-[15px]
                                    lg:text-[16px]
                                '>
                                手軽に使える月間利用とおトクな年間利用を<br className='md:hidden' />
                                ご用意しております。
                                {/* 手軽に使える月間プランとおトクな年間プランを<br className='md:hidden' />
                                ご用意しております。 */}
                            </p>
                        </Any>
                        <div
                            className='
                                w-full flex flex-col items-center gap-y-[35px]
                                md:gap-y-[46px]
                                lg:gap-y-[58px]
                            '>
                            <div
                                id={Vars.IDs.Navi_Plan_Banner}
                                className='
                                    w-full max-w-[338px] relative
                                    md:max-w-[1250px]
                                '>
                                <div
                                    className='
                                        w-full relative
                                        md:hidden
                                    '>
                                    <img
                                        src={DiscountMobile}
                                        alt="discount"
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full hidden
                                        md:block
                                    '>
                                    <img
                                        src={DiscountDesktop}
                                        alt="discount"
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                            <div
                                className='
                                    w-full flex flex-col items-center gap-y-[52px]
                                    md:gap-y-[69px]
                                    lg:gap-y-[86px]
                                '>
                                <div
                                    className={`
                                        w-full max-w-[314px] grid grid-cols-1 gap-y-6
                                        md:max-w-[1000px] md:grid-cols-2 md:gap-x-7
                                        lg:gap-x-8
                                    `}>
                                    {
                                        [Plan1, Plan2].map((plan, index) =>
                                            <Any
                                                key={index}
                                                className='
                                                    w-full flex flex-col gap-y-[10px]
                                                    md:gap-y-[13px]
                                                    lg:gap-y-[15px]
                                                '
                                                from={Vars.Animation.Item.from}
                                                to={[
                                                    {
                                                        state: Vars.Animation.Item.to,
                                                        duration: Vars.Animation.Item.Duration,
                                                        easing: Vars.Animation.Item.Easing,
                                                        delay: index * (Vars.Animation.Item.Delay || 0)
                                                    }
                                                ]}>
                                                <div
                                                    className='
                                                        w-full
                                                    '>
                                                    <img
                                                        src={plan}
                                                        alt="plan"
                                                        sizes='100%'
                                                    />
                                                </div>
                                                <a
                                                    href={Vars.URL.Register.Search}
                                                    rel='services'
                                                    target='_blank'
                                                    className={`
                                                        w-full select-none cursor-default block py-[18px] px-[10px] transition duration-300 rounded
                                                        md:px-[16px] md:py-[23px]
                                                        lg:px-[20px] lg:py-[28px]
                                                        ${
                                                            index === 0 ?
                                                                'bg-[#06B6D4] hover:bg-[#0891B2]' :
                                                                'bg-[#D30D58] hover:bg-[#c60b53]'
                                                        }
                                                        hover:-translate-y-1 hover:shadow-lg
                                                    `}>
                                                    <p
                                                        className={`
                                                            text-white text-[16px] font-bold transition-colors duration-300 text-center
                                                            md:text-[20px]
                                                            lg:text-[25px]
                                                        `}>
                                                        <span
                                                            className='
                                                                text-[12px] font-normal
                                                                md:text-[14px]
                                                                lg:text-[16px]
                                                            '>
                                                            {index === 0 ? '月' : '年'}間利用で
                                                        </span> 利用申込
                                                    </p>
                                                </a>
                                            </Any>
                                        )
                                    }
                                </div>
                                <NotePart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Plan;