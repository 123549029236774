import Any from '@/components/global/Any';
import Vars from '@/constants/vars';
import React from 'react'

const Trial = () => {
    return (
        <Any
            className='
                w-full text-[10px] text-center px-3
                md:text-[13px] md:px-6
                lg:text-[16px]
            '
            from={Vars.Animation.Description.from}
            to={[
                {
                    state: Vars.Animation.Description.to,
                    duration: Vars.Animation.Description.Duration,
                    easing: Vars.Animation.Description.Easing
                }
            ]}>
            <span className='font-bold'>無料トライアル期間は3日間</span><br className='md:hidden'/>
            (トライアル開始から72時間)<span className='font-bold'>です。</span><br />
            無料期間経過後に、選択したサブスクリプション（月間利用もしくは年間利用）が開始されます。<br />
            ※ 有料でご利用されない場合、<br className='md:hidden'/>
            無料期間内でのご解約手続きが必要です。
        </Any>
    );
}

export default Trial;