import React from 'react';
import QA from '@/components/element/navi/QA';
import Any from '@/components/global/Any';

const QAs: {
    question: string,
    answer: React.ReactNode
}[] = [
    {
        question: 'どのような情報が入っていますか？',
        answer: <>
            全国の地方自治体、国、団体が扱う個人・事業者向け、補助金・助成金・社会福祉情報 などが12万件以上入っています。
            {/* 全国の地方自治体、国、団体が扱う個人・事業者向け、補助金・助成金・社会福祉情報などが12万件以上入っています。
            国、地方自治体、団体を含めて、<br className='md:hidden'/>
            2000箇所以上の個人・事業者向け、<br />
            補助金・助成金、社会福祉情報などが<br className='md:hidden'/>
            約12万件以上入っています。 */}
        </>
    },
    {
        question: 'どのデバイスで利用可能ですか？',
        answer: <>
            インターネットが利用可能な環境で、PC、スマートフォンおよびタブレット端末からのご利用が可能です。
            {/* インターネットが利用可能な環境で、PC、スマートフォンおよびタブレット端末からのご利用が可能です */}
            {/* PC、スマートフォンおよび<br className='md:hidden'/>
            タブレット端末からのご利用が<br className='md:hidden'/>
            可能です。 */}
        </>
    },
    {
        question: 'セキュリティは安心ですか？',
        answer: <>
            8LIFEでは取り扱うすべてのデータを<br className='md:hidden'/>
            世界トップレベルの<br className='md:hidden'/>
            クラウドセキュリティを<br className='hidden md:block'/>誇る<br className='md:hidden'/>
            AWSで保存しております。<br className='md:hidden'/>
            セキュリティ機能の搭載、<br className='md:hidden'/>
            外部攻撃に対する対策などが<br />
            なされております。
            {/* <br className='md:hidden'/>
            また、弊社はプライバシーマーク、<br className='md:hidden'/>
            8LIFEはISO27001認証を取得済みです。 */}
        </>
    },
    {
        question: 'どのような支払い方法がありますか？',
        answer: <>
            クレジット決済が可能です。その他の<br className='md:hidden'/>
            お支払い方法をご希望の場合は、<br />
            弊社までお問い合わせください。
        </>
    },
    {
        question: '情報のメンテナンスはされていますか?',
        answer: <div className='leading-[200%]'>
            日々新たな情報を追加しています。<br />
            定期的に情報のメンテナンスも行っております。<br />
            {/* 定期的にメンテナンスを行っておりますので、ご安心ください。<br /> */}
            {/* 約2週間に1回メンテナンスを<br className='md:hidden'/>
            行っております。<br /> */}
            <span
                className='
                    text-[10px]
                    md:text-[11px]
                    lg:text-[12px]
                '>
                ※情報の正確性、網羅性を完全に保証するものではございません。
                {/* ※ 情報の正確性を完全に保証するものではございません。 */}
                {/* ※ 情報の正確性を完全に保証する<br className='md:hidden'/>
                ものではございません。 */}
            </span>
        </div>
    },
]

const FAQ = () => {
    return (
        <section
            className="
                mt-28 flex justify-center
                md:mt-32
                lg:mt-40
            ">
            <div
                className='
                    w-full flex justify-center max-w-[75rem] px-2.5
                    sm:px-5
                    md:px-10
                    lg:px-14
                '>
                <div
                    className='
                        w-full flex flex-col items-center gap-y-9
                        md:flex-row md:items-start md:gap-x-10
                        lg:gap-x-16
                    '>
                    <Any
                        as='h2'
                        className='
                            text-center text-[22px] font-bold md:-translate-y-[14%]
                            md:text-start md:text-[28px] md:whitespace-nowrap leading-[1.35]
                            lg:text-[35px]
                        '
                        from='opacity-0 -translate-x-[20px]'
                        to={[
                            {
                                state: 'opacity-100 translate-x-0',
                                duration: 1000,
                                easing: 'outQuart'
                            }
                        ]}>
                        <span
                            className='
                                text-[39px] leading-[1.35]
                                md:text-[67px]
                                lg:text-[96px]
                            '>
                            FAQ
                        </span><br />
                        よくある質問
                    </Any>
                    <div
                        className='
                            w-full max-w-[330px] flex flex-col gap-y-[60px]
                            md:max-w-none md:gap-y-[80px]
                            lg:gap-y-[100px]
                        '>
                        {
                            QAs.map((qa, index) =>
                                <div
                                    key={index}
                                    className='w-full'>
                                    <QA
                                        key={index}
                                        qa={qa}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default FAQ;