import React from 'react';
import FreeMobile from '@/assets/images/navi/free/free_m.png';
import FreeDesktop from '@/assets/images/navi/free/free_d.png';

const Free = () => {
    return (
        <section
            className={`
                mt-[60px] flex justify-center px-2.5
                md:px-5
                lg:px-10
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        group w-[83%] max-w-[338px] relative
                        md:w-[69%] md:max-w-[668px]
                        lg:max-w-[999px]
                    '>
                    <div
                        className='
                            w-full
                            md:hidden
                        '>
                        <img
                            src={FreeMobile}
                            alt='recommend'
                            sizes='100%'
                        />
                    </div>
                    <div
                        className='
                            w-full hidden
                            md:block
                        '>
                        <img
                            src={FreeDesktop}
                            alt='recommend'
                            sizes='100%'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Free;