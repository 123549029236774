import React from 'react';
import PlanDescription from '@/components/element/sales/PlayDescription';

const Class = {
    SmallText: `
        text-[12.5px] font-medium
        md:text-[1rem]
    `
}

type Props = {
    plan: PlanType,
    no: number
}

const PlanItem = ({ plan, no }: Props) => {
    return (
        <div
            className={`
                w-full max-w-[320px] flex flex-col items-center bg-white pt-6 pb-12 space-y-6 border px-3 rounded-lg
                sm:space-y-9 sm:px-6
                ${
                    no === 0 ?
                    'border-[#3866AB]':
                    'border-[#D30D58]'
                }
                md:max-w-[430px] md:space-y-12 md:px-9
                lg:max-w-[450px]
            `}>
            <div
                className='
                    text-[12.5px] font-medium font-noto-sans-jp
                    sm:text-base
                    md:text-lg
                '>
                {plan.title}
            </div>
            <div
                className='
                    text-center flex justify-center flex-nowrap whitespace-nowrap gap-x-0.5
                    sm:gap-x-1
                '>
                <p
                    className={`
                        ${Class.SmallText} self-start
                    `}>
                    ¥
                </p>
                <div
                    className='
                        text-[45px] font-bold
                        md:text-[5.865vw]
                        lg:text-[60px]
                    '>
                    {plan.money}
                </div>
                <p
                    className={`
                        ${Class.SmallText} self-end mb-1
                        sm:mb-[7.5px]
                        md:mb-[10px]
                        lg:mb-3
                    `}>
                    {plan.duration}
                </p>
            </div>
            <div
                className='
                    w-full max-w-[270px] flex flex-col gap-y-[30px]
                    md:gap-y-[36px]
                    lg:gap-y-[43px]
                '>
                <div
                    className='
                        flex flex-col items-start space-y-6 self-center
                        sm:space-y-7
                        md:space-y-9
                    '>
                    {
                        plan.descriptions.map((description, index) =>
                            <PlanDescription
                                key={index}
                                no={no}
                                description={description}
                            />
                        )
                    }
                </div>
                <div
                    className='
                        text-[10px]
                    '>
                    {
                        plan.note
                    }
                </div>
            </div>
        </div>
    );
}

export default PlanItem;