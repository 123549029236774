import React from 'react';
import { Class } from '@/constants/vars';
import FemaleMobile from '@/assets/images/navi/published/female_m.png';
import FemaleDesktop from '@/assets/images/navi/published/female_d.png';
import MaleMobile from '@/assets/images/navi/published/male_m.png';
import MaleDesktop from '@/assets/images/navi/published/male_d.png';
import Card from '@/components/element/navi/Card';

const Cards: FlowType[] = [
    {
        titleTop: '役に立つ情報がきっと見つかる！',
        title: '事業者向け支援情報',
        points: [
            {
                icon: require('@/assets/images/navi/published/business/business_1.png').default,
                description: '補助金'
            },
            {
                icon: require('@/assets/images/navi/published/business/business_2.png').default,
                description: '助成金'
            },
            {
                icon: require('@/assets/images/navi/published/business/business_3.png').default,
                description: '融資'
            },
            {
                icon: require('@/assets/images/navi/published/business/business_4.png').default,
                description: '税制'
            }
        ]
    },
    {
        titleTop: '知って得する公的保険！',
        title: '社会補償制度',
        points: [
            {
                icon: require('@/assets/images/navi/published/social/social_1.png').default,
                description: '医療保険'
            },
            {
                icon: require('@/assets/images/navi/published/social/social_2.png').default,
                description: '労災保険'
            },
            {
                icon: require('@/assets/images/navi/published/social/social_3.png').default,
                description: '雇用保険'
            },
            {
                icon: require('@/assets/images/navi/published/social/social_4.png').default,
                description: <>
                    自立支援<br />
                    医療
                </>
            },
            {
                icon: require('@/assets/images/navi/published/social/social_5.png').default,
                description: <>
                    障害福祉<br />
                    サービス
                </>
            },
            {
                icon: require('@/assets/images/navi/published/social/social_6.png').default,
                description: '年金'
            },
            {
                icon: require('@/assets/images/navi/published/social/social_7.png').default,
                description: '介護保険'
            }
        ]
    },
    {
        titleTop: 'ライフシーン毎に利用できる補助金や助成金！',
        title: '個人向け支援情報',
        points: [
            {
                icon: require('@/assets/images/navi/published/personal/personal_1.png').default,
                description: '就業'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_2.png').default,
                description: '結婚'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_3.png').default,
                description: '出産'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_4.png').default,
                description: '子育て'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_5.png').default,
                description: <>
                    住宅・<br />
                    リフォーム
                </>
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_6.png').default,
                description: 'シニア'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_7.png').default,
                description: '災害'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_8.png').default,
                description: '病気'
            },
            {
                icon: require('@/assets/images/navi/published/personal/personal_9.png').default,
                description: '事故'
            }
        ]
    }
];

const Published = () => {
    return (
        <section
            className={Class.Section_Home}>
            <div
                className='
                    w-full flex justify-center max-w-[76.5rem] px-2.5
                    sm:px-5
                    md:px-10
                    lg:px-14
                '>
                <div
                    className='
                        w-full flex flex-col items-center gap-y-[44px]
                        md:flex-row md:justify-between md:gap-x-[57px]
                        lg:gap-x-[70px]
                    '>
                    <div
                        className='
                            w-full max-w-[320px] flex flex-col items-center gap-y-11
                            md:max-w-none md:w-fit md:items-start
                        '>
                        <h3
                            className='
                                text-center font-bold text-[22px] leading-[180%]
                                md:text-[30px]
                                lg:text-[39px]
                            '>
                            <span
                                className='
                                    text-[40px]
                                    md:text-[68px]
                                    lg:text-[96px]
                                '>
                                8LIFE
                            </span>の<br />
                            <span className='tracking-[2.34px]'>掲載データ</span>
                        </h3>
                        <div
                            className='
                                w-full flex flex-col items-center gap-y-6
                                md:items-start md:gap-y-8
                                lg:gap-y-11
                            '>
                            <div
                                className='
                                    w-[90%]
                                    md:max-w-[369px]
                                    lg:max-w-[455px]
                                '>
                                <div
                                    className='
                                        w-full
                                        md:hidden
                                    '>
                                    <img
                                        src={FemaleMobile}
                                        alt="female-uses"
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full hidden
                                        md:block
                                    '>
                                    <img
                                        src={FemaleDesktop}
                                        alt="female-uses"
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                            <div
                                className='
                                    w-full
                                    md:max-w-[419px]
                                    lg:max-w-[519px]
                                '>
                                <div
                                    className='
                                        w-full
                                        md:hidden
                                    '>
                                    <img
                                        src={MaleMobile}
                                        alt="male-uses"
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full hidden
                                        md:block
                                    '>
                                    <img
                                        src={MaleDesktop}
                                        alt="male-uses"
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='
                            w-full flex flex-col items-center gap-y-[24px]
                            sm:gap-y-[48px]
                            md:gap-y-[70px]
                        '>
                        {
                            Cards.map((card, index) =>
                                <Card
                                    key={index}
                                    card={card}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Published;