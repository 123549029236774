import React from 'react'
import Layout from '../../components/layouts/layout'
import Seo from '@/components/Seo';

const Complete = () => {
  return (
    <Layout>
        <section id="contact" className='text-[15px] my-[30px] mx-atuo flex justify-center items-center'>
            <div className='my-[50px] text-gray-700 px-[10px]'>
                    <h2 className='text-[22.5px] flex justify-center items-center leading-[25px] my-[20px] text-amber-700'>
                        資料請求いただき<br className=' md:hidden'/>ありがとうございます
                    </h2>
                    {/* <p className='flex justify-center items-center mt-8'>
                        この度は8LIFEへお問い合わせ頂きまして、誠にありがとうございました。<br/>
                    </p>
                    <p className='flex justify-center items-center mt-2'>
                    お問い合わせ内容を拝見させていただき、原則3営業日以内に担当より折り返しのご連絡をさせて頂きたいと思います。
                    </p> */}
                    <p className='mt-[30px] flex justify-center items-center text-[15px]'>
                        尚、ご記入いただきましたメールアドレス宛に自動返信メールを送付致しました。
                        もしメールが届いていない場合は恐れ入りますが、 下記よりメールを送信ください。
                    </p>
                    <div className='flex justify-center items-center mt-[20px]'>
                        <div className='flex flex-col space-y-[2.5px]'>
                            <span>メールアドレス：<a href="mailto:sales@8life.cloud" className='text-cyan-600 hover:text-cyan-800 transition-colors duration-200'>sales@8life.cloud</a></span>
                            <span>電話番号: TEL 03-6262-6157</span>
                        </div>
                    </div>
            </div>
        </section>
    </Layout>
  )
}

export default Complete;

export const Head = () => (
    <Seo title="WP1 - 資料請求いただき | 8LIFE"/>
);