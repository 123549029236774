import React from 'react';
import { Class } from '@/constants/vars';
import Any from '@/components/global/Any';
import Trial from '@/components/global/Trial';
import Response from '@/assets/images/navi/note_bottom/response.png';

const NoteBottom = () => {
    return (
        <section
            id='note-bottom'
            className={`
                ${Class.Section_Home} px-2.5
                md:px-5
                lg:px-10
            `}>
            <div
                className='
                    w-full flex flex-col items-center
                '>
                <div
                    className='
                        w-full max-w-[329px] relative flex justify-center border-[2px] border-[#CACACA] pt-[55px] pb-[76px] px-2.5 bg-white
                        md:max-w-[830px] md:pt-[60px] md:pb-[69px] md:px-5
                        lg:max-w-[1330px] lg:pt-[66px] lg:pb-[63px] lg:px-10 lg:border-[3px]
                    '>
                    <div
                        className='
                            w-full space-y-6
                            sm:space-y-7
                            md:space-y-9
                        '>
                        <Any
                            className='flex justify-center'
                            from='opacity-0 translate-y-[40px]'
                            to={[
                                {
                
                                    state: 'opacity-100 translate-y-0',
                                    duration: 1000,
                                    easing: 'outQuart'
                                }
                            ]}>
                            <a
                                href="https://app.8life.cloud/auth/register?plan=search"
                                rel='plan'
                                target='_blank'
                                className='
                                    w-[90%] max-w-[400px] px-1 py-3 text-[1rem] font-bold text-center block transition-colors duration-200 bg-[#D30D58] rounded-[5px] text-white border border-[#D30D58]
                                    md:w-full md:max-w-[500px] md:text-[1.125rem] md:py-5
                                    lg:max-w-[550px] lg:text-[18px] lg:py-[1.25rem]
                                    hover:text-[#D30D58] hover:bg-white
                                '>
                                今すぐ無料トライアル
                            </a>
                        </Any>
                        <Trial />
                    </div>
                    <div
                        className='
                            w-[158px] absolute top-0 right-2.5 -translate-y-[50%]
                            md:w-[239px] md:right-5 md:-translate-y-[60%]
                            lg:w-[321px] lg:right-5 lg:-translate-y-[70%]
                        '>
                        <img
                            src={Response}
                            alt="stand"
                            sizes="100%"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NoteBottom;