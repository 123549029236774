exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[slug]-js": () => import("./../../../src/pages/blog/[slug].js" /* webpackChunkName: "component---src-pages-blog-[slug]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-single-blog-js": () => import("./../../../src/pages/blog/single-blog.js" /* webpackChunkName: "component---src-pages-blog-single-blog-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-navi-index-tsx": () => import("./../../../src/pages/navi/index.tsx" /* webpackChunkName: "component---src-pages-navi-index-tsx" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-sales-index-tsx": () => import("./../../../src/pages/sales/index.tsx" /* webpackChunkName: "component---src-pages-sales-index-tsx" */),
  "component---src-pages-wp-1-complete-js": () => import("./../../../src/pages/wp-1/complete.js" /* webpackChunkName: "component---src-pages-wp-1-complete-js" */),
  "component---src-pages-wp-1-index-js": () => import("./../../../src/pages/wp-1/index.js" /* webpackChunkName: "component---src-pages-wp-1-index-js" */),
  "component---src-pages-wp-2-complete-js": () => import("./../../../src/pages/wp-2/complete.js" /* webpackChunkName: "component---src-pages-wp-2-complete-js" */),
  "component---src-pages-wp-2-index-js": () => import("./../../../src/pages/wp-2/index.js" /* webpackChunkName: "component---src-pages-wp-2-index-js" */),
  "component---src-pages-wp-3-complete-js": () => import("./../../../src/pages/wp-3/complete.js" /* webpackChunkName: "component---src-pages-wp-3-complete-js" */),
  "component---src-pages-wp-3-index-js": () => import("./../../../src/pages/wp-3/index.js" /* webpackChunkName: "component---src-pages-wp-3-index-js" */),
  "component---src-pages-wp-4-complete-js": () => import("./../../../src/pages/wp-4/complete.js" /* webpackChunkName: "component---src-pages-wp-4-complete-js" */),
  "component---src-pages-wp-4-index-js": () => import("./../../../src/pages/wp-4/index.js" /* webpackChunkName: "component---src-pages-wp-4-index-js" */)
}

