import React from 'react';
import Sticker from '../../../global/Sticker';
import Routes from '@/constants/routes';
import PlayCyan from '@/assets/images/home/hero/play_cyan.svg';
import PlayBlue from '@/assets/images/home/hero/play_blue.png';
import Vars from '@/constants/vars';
import { twMerge } from 'tailwind-merge';
import { Link } from 'gatsby';

type Props = {
    price: Price,
    type: CardType
}

const PlanItem = ({ price, type }: Props) => {
    return (
        <div
            className={`
                w-full max-w-[320px] flex flex-col items-center bg-white py-5 pb-2.5 gap-y-6 px-2.5 rounded-xl shadow-md
                md:max-w-none md:gap-y-8 md:px-4 md:py-4
                lg:gap-y-10 lg:px-6 lg:py-6
                xl:px-10 xl:py-10
            `}>
            <div
                className='
                    w-full max-w-full flex flex-col items-center gap-y-2.5
                    md:flex-row md:items-end md:justify-center md:gap-x-2 md:whitespace-nowrap
                    lg:gap-x-3.5
                '>
                <div
                    className='
                        w-full flex flex-col items-center
                        md:max-w-[235px]
                        lg:max-w-[270px]
                    '>
                    {
                        type === 'navi' &&
                            <div
                                className='
                                    hidden h-0 opacity-0 select-none pointer-events-none
                                    md:block md:h-[16px]
                                    lg:h-[19px]
                                '>
                                hidden
                            </div>
                    }
                    <Sticker
                        size='small'
                        type={type}
                        labelClassName='
                            md:text-[1.369vw]
                            lg:text-[16px]
                        '
                        buttonClassName='
                            md:text-[2.737vw]
                            lg:text-[32px]
                        '
                        jpTextClassName='
                            md:text-[1.857vw]
                            lg:text-[22px]
                        '
                    />
                </div>
                <Link
                    to={type === 'navi' ? Routes.NAVI : Routes.SALES}
                    rel='services'
                    className='relative'>
                    <div
                        className={`
                            w-full h-full absolute bottom-[-5px] left-0 rounded-full
                            lg:bottom-[-6px]
                            ${
                                type === 'navi' ?
                                'bg-[#B4E9F2]' :
                                'bg-[#AFCFFE]'
                            }
                        `}>
                    </div>
                    <div
                        className={`
                            relative flex justify-center select-none items-center gap-x-2 py-[6px] px-[1rem] border rounded-full transition-colors bg-white
                            md:gap-x-2 md:py-[3.5px] md:px-3
                            lg:gap-x-3 lg:px-4 lg:py-[9px]
                            ${
                                type === 'navi' ?
                                'border-[#06B6D4] hover:bg-[#f6feff]' :
                                'border-[#3866AB] hover:bg-[#f6f8fd]'
                            }
                        `}>
                        <div
                            className='
                                w-[8px]
                                lg:w-[9px]
                            '>
                            <img
                                src={type === 'navi' ? PlayCyan : PlayBlue}
                                alt="play"
                                sizes='100%'
                            />
                        </div>
                        <p
                            className={`
                                text-[12px] font-bold
                                md:text-[13px]
                                lg:text-[14px]
                                ${
                                    type === 'navi' ?
                                        'text-[#06B6D4]' :
                                        'text-[#3866AB]'
                                }
                            `}>
                            サービスサイト
                        </p>
                    </div>
                </Link>
            </div>
            <div
                className='
                    w-full flex flex-col gap-y-[5px]
                    md:gap-y-[10px]
                    lg:gap-y-[14px]
                '>
                {
                    (Array.from(Array(2))).map((_, index) =>
                        <div
                            key={index}
                            className='
                                w-full flex flex-col items-center gap-y-2.5 bg-[#DBDBDB] bg-opacity-[7%] px-2.5 py-2.5 pb-4 rounded
                                md:px-3 md:flex-row md:py-4 md:gap-x-10 md:whitespace-nowrap
                                lg:px-5 lg:py-6 lg:gap-x-14
                            '>
                            <p
                                className={`
                                    text-[14px] font-medium
                                    md:text-[1.76vw]
                                    lg:text-[1.72vw]
                                    xl:text-[22px]
                                    ${
                                        index === 0 ? (type === 'navi' ? 'text-[#06B6D4]' : 'text-[#3866AB]') : 'text-[#D30D58]'
                                    }
                                `}>
                                {index === 0 ? '月間利用' : '年間利用'}
                            </p>
                            <div
                                className={twMerge(`
                                    w-full max-w-[204px] flex items-center
                                    md:max-w-[278px] md:h-[78px]
                                    lg:max-w-[352px] lg:md:h-[95.97px]
                                `, index === 0 ? price.month.className : price.year.className)}>
                                <div
                                    className='w-full'>
                                    <img
                                        src={index === 0 ? price.month.value : price.year.value}
                                        alt='image'
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                            {/* <div
                                className='
                                    flex items-center gap-x-1
                                    md:gap-x-2
                                    lg:gap-x-3
                                '>
                                <p
                                    className='
                                        text-[18px] font-noto-sans-jp font-medium leading-[1]
                                        md:text-[2.248vw]
                                        lg:text-[2.189vw]
                                        xl:text-[28px]
                                    '>
                                    ¥
                                </p>
                                <div
                                    className='
                                        flex items-end
                                    '>
                                    <p
                                        className={`
                                            text-[30px] font-bold leading-[1]
                                            md:text-[4.399vw]
                                            lg:text-[4.691vw]
                                            xl:text-[60px]
                                            ${index === 0 ? 'text-[#06B6D4]' : 'text-[#D30D58]'}
                                        `}>
                                        {
                                            index === 0 ?
                                                '1,980' :
                                                type === 'navi' ?
                                                    '15,840' :
                                                    '19,800'
                                        }
                                    </p>
                                    <p
                                        className='
                                            text-[12px] font-medium
                                            md:text-[1.466vw]
                                            lg:text-[1.407vw]
                                            xl:text-[18px]
                                        '>
                                        {index === 0 ? '/月（税込）' : '/年（税込）'}
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    )
                }
                {
                    type === 'sales' &&
                    <p
                        className='
                            text-[10px]
                        '>
                        {/* ※ IT導入補助金を活用しない場合の料金です。活用する場合については、サービスサイトをご確認ください。 */}
                        ※ <span className='font-bold text-[#D30D58]'>IT導入補助金をご活用いただくことが可能です。</span>条件・料金等については、サービスサイトをご確認ください。<br />
                        ※ <span className='font-bold text-[#D30D58]'>上記はIT導入補助金を活用しない場合の料金です。</span>
                    </p>
                }
            </div>

            <div
                className='
                    w-full flex-1 flex flex-col justify-end
                '>
                <a
                    href={type === 'navi' ? Vars.URL.Register.Navi : Vars.URL.Register.Sales}
                    rel='services'
                    target='_blank'
                    className={`
                        w-full select-none cursor-default block py-3 px-[10px] transition duration-300 rounded
                        md:px-[16px] md:py-3.5
                        lg:px-[20px] lg:py-4
                        ${
                            type === 'navi' ?
                                'bg-[#06B6D4] hover:bg-[#0891B2]' :
                                'bg-[#3866AB] hover:bg-[#305893]'
                        }
                        hover:-translate-y-1 hover:shadow-lg
                    `}>
                    <p
                        className={`
                            text-white text-[14px] font-bold transition-colors duration-300 text-center
                            md:text-[21px]
                            lg:text-[28px]
                        `}>
                        今すぐ無料トライアル
                        {/* 今すぐ利用開始 */}
                    </p>
                </a>
            </div>
        </div>
    );
}

export default PlanItem;