import React, { useEffect, useRef, useState } from "react";
import ArrowLeft from "@/assets/images/sales/use_case/arrow_left.svg";
import ArrowRight from "@/assets/images/sales/use_case/arrow_right.svg";
import { twMerge } from "tailwind-merge";
import { EasingClass } from "./Any";

type Default = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> & {
	show: number,
	easing: Easing,
	duration: number,
	children: React.ReactNode[],
	
	auto?: undefined,
	timeout?: undefined,
	hoverPause?: undefined,
	direction?: undefined,

	onWindowResize?: (size: number) => void,
	onMoveStart?: (index: number, direction: 'left'|'right') => void,
	onMoveEnd?: () => void,
}

type PropsAuto =  Omit<Default, 'auto'|'timeout'|'hoverPause'|'direction'> & {
	auto: boolean,
	timeout: number,
	hoverPause: boolean,
	direction: 'left'|'right'
}

const Class = {
	Button: `
		cursor-default
	`,
	ContentOutSide: `
		min-w-full min-h-full flex items-stretch
	`,
	Inside: `
		min-w-full min-h-full flex justify-center items-stretch overflow-hidden
	`,
	Child: `
		w-full h-full flex-1 bg-red-600
	`
}

const Arrow = ({ side }: { side: 'right'|'left' }) => {
	return (
		<div
			className="
				w-[25px]
				sm:w-[30px]
				md:w-[35px]
				lg:w-[40px]
			">
			<img
				src={side === 'left' ? ArrowLeft : ArrowRight}
				alt="arrow"
				sizes="100%"
			/>
		</div>
	);
}

const Slider = ({
	show,
	easing,
	duration,
	children,
	auto,
	timeout,
	hoverPause,
	direction,
	onWindowResize,
	onMoveStart,
	onMoveEnd,
	...rest
}: Default|PropsAuto) => {
	const [currentPage, setCurrentPage] = useState(0);
	const [page, setPage] = useState(0);
	const [mouseEntered, setMouseEntered] = useState(false);
	const timeoutRef = useRef(undefined as undefined|NodeJS.Timeout);

	const onPrevClick = () => {
		clearTimeout(timeoutRef.current);
		setPage(page - 1);
	}

	const onNextClick = () => {
		clearTimeout(timeoutRef.current);
		setPage(page + 1);
	}

	const calculateIndex = (index: number) => {
		if (index > children.length - 1) {
			index = index - children.length * Math.floor(index / children.length);
		} else if (index < 0) {
			index = index + children.length * Math.ceil(Math.abs(index / children.length));
		}

		return index;
	}

	const timerToAnimate = () => {
		if (auto === true) {
			clearTimeout(timeoutRef.current);

			timeoutRef.current = setTimeout(
				() => {
					if (direction === 'right') {
						setPage(page + 1);
					} else {
						setPage(page - 1);
					}
				},
				timeout
			);
		}
	}

	useEffect(() => {
		if (onWindowResize !== undefined) {
			onWindowResize(window.innerWidth);
		}
	}, []);

	useEffect(() => {
		setPage(0);
		setCurrentPage(0);
	}, [show]);

	useEffect(() => {
		if (page !== currentPage) {
			if (onMoveStart !== undefined) {
				onMoveStart(
					calculateIndex(page),
					(page - currentPage) > 0 ? 'right' : 'left'
				);
			}
		} else {
			if (onMoveEnd !== undefined) {
				onMoveEnd();
			}
		}

		if (page === 0 && currentPage === 0) {
			clearTimeout(timeoutRef.current);

			if (mouseEntered === false) {
				timerToAnimate();
			}
		}
	}, [page, currentPage]);

	useEffect(() => {
		clearTimeout(timeoutRef.current);

		if (mouseEntered === false) {
			timerToAnimate();
		}

		function onResize() {
			clearTimeout(timeoutRef.current);

			if (mouseEntered === false) {
				timerToAnimate();
			}

			setPage(0);
			setCurrentPage(0);


			if (onWindowResize !== undefined) {
				onWindowResize(window.innerWidth);
			}
		}

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		}
	}, [onWindowResize, mouseEntered]);

	return (
		<div
			{...rest}
			className={
				twMerge(
					rest.className,
					`
						w-full h-full flex items-stretch gap-x-2.5
						
					`
				)
			}
			onMouseEnter={() => {
				if (hoverPause === true) {
					clearTimeout(timeoutRef.current);
					setMouseEntered(true);
				}
			}}
			onMouseLeave={() => {
				setMouseEntered(false);
			}}>
			<button
				disabled={currentPage !== page}
				className={`
					${Class.Button} left-0
				`}
				onClick={() => {
					onPrevClick();
				}}>
				<Arrow  side="left" />
			</button>

			<div className='w-full h-full overflow-hidden'>
				<div
					className={
						twMerge(
							"w-full h-full flex items-stretch",
							EasingClass(easing)
						)
					}
					style={{
						transitionProperty: (page === 0 && currentPage === 0) ? undefined : 'transform',
						msTransitionProperty: (page === 0 && currentPage === 0) ? undefined : 'transform',
						MozTransitionProperty: (page === 0 && currentPage === 0) ? undefined : 'transform',
						WebkitTransitionProperty: (page === 0 && currentPage === 0) ? undefined : 'transform',

						transitionDuration:  ((page === 0 && currentPage === 0) ? 0 : Math.abs(duration)) + 'ms',
						msTransitionDuration: ((page === 0 && currentPage === 0) ? 0 : Math.abs(duration)) + 'ms',
						MozTransitionDuration: ((page === 0 && currentPage === 0) ? 0 : Math.abs(duration)) + 'ms',
						WebkitTransitionDuration: ((page === 0 && currentPage === 0) ? 0 : Math.abs(duration)) + 'ms',

						transform: `translateX(${-100 - page * (100/show)}%)`,
						msTransform: `translateX(${-100 - page * (100/show)}%)`,
						WebkitTransform: `translateX(${-100 - page * (100/show)}%)`
					}}
					onTransitionEnd={() => {
						if (Math.abs(page) === children.length) {
							setPage(0);
							setCurrentPage(0);
						} else {
							setCurrentPage(page);

							if (mouseEntered === false) {
								timerToAnimate();
							}
						}
					}}>
					<div className={`${Class.ContentOutSide} justify-end`}>
						{
							Array.from(Array(Math.ceil(children.length/show))).map((_, pageIndex) => {
								const startIndex = calculateIndex(
									children.length - show * Math.ceil(children.length/show) +
									pageIndex * show
								);

								return (
									<div
										key={pageIndex}
										className={Class.Inside}>
										{
											Array.from(Array(show)).map((_, index) => 
												// <div
												// 	key={index}
												// 	className={Class.Child}>
												// 	{
														children[calculateIndex(startIndex + index)]
												// 	}
												// </div>
											)
										}
									</div>
								);
							})
						}
					</div>

					<div className={Class.ContentOutSide}>
						{
							Array.from(Array(Math.ceil(children.length/show) + 1)).map((_, pageIndex) => {
								const startIndex = calculateIndex(pageIndex * show);

								return (
									<div
										key={pageIndex}
										className={Class.Inside}>
										{
											Array.from(Array(show)).map((_, index) =>
												// <div
												// 	key={index}
												// 	className={Class.Child}>
													// {
														children[calculateIndex(startIndex + index)]
													// }
												// </div>
											)
										}
									</div>
								);
							})
						}
					</div>
				</div>
			</div>

			<button
				disabled={currentPage !== page}
				className={`
					${Class.Button} right-0
				`}
				onClick={() => {
					onNextClick();
				}}>
				<Arrow side="right" />
			</button>
		</div>
	);
}

export default Slider;
