import React from "react";
import { twMerge } from "tailwind-merge";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> & {
    duration: number,
    reverse?: boolean,
    pause?: boolean,
    children: React.ReactElement[],
}

const Swipper = ({reverse, duration, pause, ...props}: Props) => {
    return (
        <div className="w-fit overflow-hidden">
            <div className={`w-fit overflow-hidden flex ${reverse === true ? '-translate-x-1/3' : 'translate-x-0'}`}>
                {
                    (Array.from(Array(3))).map(((_, index) =>
                        <div
                            key={index}
                            {...props}
                            className={
                                twMerge(
                                    `flex w-fit flex-shrink-0 ${reverse === true ? 'animate-swipe-right' : 'animate-swipe'}`,
                                    props.className
                                )
                            }
                            style={{
                                animationDuration: duration + 'ms',
                                animationPlayState: pause === true ? 'paused' : 'running',
                                ...props.style,
                            }}>
                            {props.children}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};
  
export default Swipper;
  