import React from 'react';
import Any from '@/components/global/Any';
import CustomerImage from '@/assets/images/sales/customer/customer.svg';
import { twMerge } from 'tailwind-merge';

const Customer = ({ className }: { className?: string }) => {
    return (
        <section
            className={twMerge(`
                mt-12 flex justify-center
                sm:mt-[60px]
                md:mt-20
            `, className)}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        relative w-full flex justify-center
                    '>
                    <Any
                        className='
                            w-full relative
                            md:max-h-[230px]
                        '
                        from="opacity-0"
                        to={[
                            {
                                state: 'opacity-100',
                                duration: 2500,
                                easing: 'outQuad',
                            }
                        ]}>
                        <div
                            className="
                                w-full max-w-[800px] relative px-4 py-10 mx-auto space-y-4 overflow-hidden
                                sm:space-y-6 sm:py-12
                                md:space-y-10 md:py-[70px]
                            ">

                            <div
                                className='
                                    relative z-10 text-center text-[#06B6D4] text-[3.5vw]
                                    sm:text-base
                                '>
                                <div
                                    className='
                                        leading-10 flex flex-col gap-y-[5px]
                                        md:gap-y-[10px]
                                    '>
                                    12万件以上の情報の中から、お客様に関心を<p className='md:hidden'></p>
                                    持っていただけそうな情報を自動でシステムが<p></p>
                                    絞り込み、お客様から信頼される情報提供型の<p className='md:hidden'></p>
                                    営業を支援します。
                                </div>
                            </div>
                        </div>

                        <div className="absolute inset-0">
                            <img
                                src={CustomerImage}
                                alt="customer-back"
                                sizes="100%"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </Any>
                </div>
            </div>
        </section>
    );
}

export default Customer;