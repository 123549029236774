import React from 'react';
import Any from '@/components/global/Any';
import Sticker from '@/components/global/Sticker';
import MedalOne from '@/assets/images/navi/hero/medal_1.png';
import MedalTwo from '@/assets/images/navi/hero/medal_2.png';
import MedalThree from '@/assets/images/navi/hero/medal_3.png';
import HeroImage from '@/assets/images/navi/hero/hero.png';
import TitleBottom from '@/assets/images/navi/hero/title_b.png';
import LineTop from '@/assets/images/navi/hero/line_t.svg';
import LineBottom from '@/assets/images/navi/hero/line_b.svg';
// import TitleMobile from '@/assets/images/navi/hero/hero_title_m.png';
import TitleDesktop from '@/assets/images/navi/hero/hero_title_d.png';

const Medals: { src: string, alt: string }[] = [
    {
        src: MedalOne,
        alt: '日本最大級情報10万件以上'
    },
    {
        src: MedalTwo,
        alt: '日本全国1700自治体対応'
    },
    {
        src: MedalThree,
        alt: '利用者の満足度97％以上'
    }
];

const Delay = 250;

const Hero = () => {
    return (
        <div
            className='
                w-full flex justify-center mt-[50px]
            '>
            <div
                className='
                    w-full relative flex justify-center max-w-[1353px] px-2.5
                    sm:px-5
                    md:px-10
                    lg:px-14
                '>
                <div
                    className='
                        absolute max-w-full top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden
                        md:left-0 md:translate-x-1/4
                    '>
                    <Any
                        className='
                            px-2.5
                        '
                        instant
                        from='translate-x-[-20px] opacity-0'
                        to={[
                            {
                                state: 'translate-x-0 opacity-100',
                                duration: 1000,
                                easing: 'outQuad',
                            }
                        ]}>
                        <div
                            className='
                                bg-white p-[2px]
                            '>
                            <Sticker
                                type='navi'
                                size='wise'
                            />
                        </div>
                    </Any>
                </div>
                <div
                    className='
                        w-full flex justify-center bg-[#06B6D4] rounded-[0.438rem]
                        md:rounded-[0.65rem]
                    '>
                    <div
                        className='
                            w-full flex flex-col items-center gap-y-8 pt-12 pb-8
                            md:max-w-none md:flex-row md:justify-between md:items-center md:gap-x-10
                            lg:gap-x-14
                        '>
                        <div
                            className='
                                w-full
                                md:w-[45%]
                            '>
                            <div
                                className='
                                    w-full flex flex-col items-center gap-y-[42px]
                                    md:hidden
                                '>
                                <p
                                    className='
                                        w-full text-[14px] text-emphasis font-bold font-gothic underline underline-offset-[10px] text-center bg-[#F7FAFB] py-[10px] px-2.5
                                    '>
                                    知らなかった！使える情報、予想以上！
                                </p>
                                <div
                                    className='
                                        w-full max-w-[266px]
                                    '>
                                    <img
                                        src={TitleBottom}
                                        alt='hero-title'
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                            <div
                                className='
                                    w-full hidden
                                    md:block
                                '>
                                <img
                                    src={TitleDesktop}
                                    alt='hero-title'
                                    sizes='100%'
                                />
                            </div>
                        </div>
                        <div
                            className='
                                w-full max-w-[350px] flex flex-col items-center px-2.5
                                md:w-[37%] md:max-w-none md:mr-10
                                lg:mr-16
                            '>
                            <Any
                                className='
                                    w-full
                                    md:max-w-[390px]
                                    lg:max-w-[480px]
                                '
                                instant
                                from='translate-x-[-20px] opacity-0'
                                to={[
                                    {
                                        state: 'translate-x-0 opacity-100',
                                        duration: 1000,
                                        easing: 'outQuad',
                                        // delay: Delay * 2
                                    }
                                ]}>
                                <img
                                    src={HeroImage}
                                    alt="hero"
                                    sizes='100%'
                                />
                            </Any>
                            <div
                                className='
                                    w-full flex flex-col gap-y-3
                                    md:gap-y-5
                                    lg:gap-y-6
                                '>
                                <div
                                    className='w-full'>
                                    <img
                                        src={LineTop}
                                        alt="line-top"
                                        sizes='100%'
                                    />
                                </div>
                                <div
                                    className='
                                        w-full flex justify-center gap-x-5
                                        md:gap-x-7
                                        lg:gap-x-9
                                    '>
                                    {
                                        Medals.map((medal, index) =>
                                            <Any
                                                key={index}
                                                className='
                                                    w-full flex-1 max-w-[100px]
                                                    sm:max-w-[140px]
                                                    md:max-w-none
                                                '
                                                instant
                                                from='translate-x-[-20px] opacity-0'
                                                to={[
                                                    {
                                                        state: 'translate-x-0 opacity-100',
                                                        duration: 1000,
                                                        easing: 'outQuad',
                                                        // delay: Delay * 3 + Delay * index
                                                        delay: Delay + Delay * index
                                                    }
                                                ]}>
                                                <img
                                                    src={medal.src}
                                                    alt={medal.alt}
                                                    sizes='100%'
                                                />
                                            </Any>
                                        )
                                    }
                                </div>
                                <div
                                    className='w-full'>
                                    <img
                                        src={LineBottom}
                                        alt="line-bottom"
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;