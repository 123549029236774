import React, { Fragment, useState } from "react";

import Banner from '@/assets/images/sales/multi_devices.png';
// import MedalOne from '@/assets/images/sales/Frame_184.svg';
// import MedalTwo from '@/assets/images/sales/medal/medal_2.svg';
// import MedalThree from '@/assets/images/sales/medal/medal_3.svg';

import MedalOne from '@/assets/images/home/hero/medal_1.png';
import MedalTwo from '@/assets/images/home/hero/medal_2.webp';
import MedalThree from '@/assets/images/home/hero/medal_3.png';

// import playBtn from '@/assets/images/sales/play_btn.svg';

import Seo from "@/components/Seo";
import Layout from "@/components/layouts/layout";
import GroupCompany from "@/components/GroupCompany";
import Step from "@/components/template/sales/Step";
import Ticks from "@/components/template/sales/Ticks";
import Flows from "@/components/template/sales/Flows";
import Features from "@/components/template/sales/Features";
import UseCases from "@/components/template/sales/UseCases";
import Plan from "@/components/template/sales/Plan";
import Download from "@/components/template/sales/Download";
import Qa from "@/components/template/sales/Q_a";
import { Dialog, Transition } from "@headlessui/react";
import ReactPlayer from "react-player";
import Any from "@/components/global/Any";
import Vars, { Class } from "@/constants/vars";
import Customer from "@/components/template/sales/Customer";
import Subsidy from "@/components/template/sales/Subsidy";
import News from "@/components/template/sales/News";
import Sticker from "@/components/global/Sticker";
import Note from "@/components/template/sales/Note";
import Media from "@/components/global/Media";
// import SpaceBottom from "@/components/template/global/SpaceBottom";
import Free from "@/components/template/sales/Free";
import NaviCard from "@/components/template/sales/NaviCard";


const Medals: {src: string, alt: string}[] = [
    {
        src: MedalOne,
        alt: '日本最大級情報10万件以上'
    },
    {
        src: MedalTwo,
        alt: '日本全国1700自治体対応'
    },
    {
        src: MedalThree,
        alt: '利用者の満足度97％以上'
    }
];

const Durations = {
    Main: 1000,
}

const IndexPage = () => {
    const [open, setOpen] = useState(false);
    const [startMedal, setStartMedal] = useState(false);

    return (
        <Layout
            toId="note">
            <div className="toppage">
                <div className='order-1 text-center toppage__contact'>
                    <Any
                        className={`
                            toppage__contact--img flex flex-col items-center space-y-3
                             md:ml-6 md:-mt-14 md:space-y-6
                             lg:ml-10
                        `}
                        from="opacity-0 -translate-y-[40px]"
                        to={[
                            {
                                state: 'opacity-100 translate-y-0',
                                duration: Durations.Main,
                                easing: 'outQuad'
                            }
                        ]}>
                        <img
                            src={Banner}
                            alt='8link page'
                            className={`
                                min-w-[150%]
                            `}
                        />

                        <div className="flex justify-center w-full">
                            <div
                                className={`
                                    flex space-x-1 items-center bg-[#D30D58] rounded-full text-white transition cursor-default
                                    px-3 py-1.5
                                    md:px-6 md:py-2.5
                                    hover:bg-[#c60b53]
                                `}
                                onClick={() => setOpen(true)}>
                                <p
                                    className={`
                                        text-[12.5px] font-medium whitespace-nowrap
                                        sm:text-lg
                                        md:text-[22px]
                                        lg:text-3xl
                                    `}>
                                    紹介動画を見る
                                </p>
                                <div
                                    className={`
                                        w-6 h-w-6
                                        sm:w-9 sm:h-w-9
                                        md:w-10 md:h-w-10
                                        lg:w-12 lg:h-w-12
                                    `}>
                                    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle
                                            cx="28" cy="28" r="28"
                                            fill="white"
                                        />
                                        <path
                                            d="M43.5791 26.5297C44.9124 27.2995 44.9124 29.224 43.5791 29.9938L22.1025 42.3933C20.7691 43.1631 19.1025 42.2009 19.1025 40.6613L19.1025 15.8622C19.1025 14.3226 20.7691 13.3603 22.1025 14.1301L43.5791 26.5297Z"
                                            fill="#D30D58"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
  
                    </Any>

                    <ul className="toppage__medal sp">
                        {
                            Medals.map((each, index) =>
                                <Any
                                    key={index}
                                    as="li"
                                    start={startMedal}
                                    from={Vars.Animation.Item.from}
                                    to={[
                                        {
                                            state: Vars.Animation.Item.to,
                                            duration: Vars.Animation.Item.Duration,
                                            easing: Vars.Animation.Item.Easing,
                                            delay: index * (Vars.Animation.Item.Delay || 0)
                                        }
                                    ]}>
                                    <img
                                        src={each.src}
                                        alt={each.alt}
                                        sizes="100%"
                                    />
                                </Any>
                            )
                        }
                    </ul>
                </div>
                <div
                    className={`
                        w-full md:w-[60%] flex flex-col justify-center
                        md:-ml-12 md:mt-3
                        lg:-ml-20 lg:mt-6
                    `}>

                    <div className='toppage__detail'>
                        <div className='toppage__detail__block'>
                            <Any
                                from="opacity-0 translate-y-[40px]"
                                to={[
                                    {
                                        state: 'opacity-100 translate-y-0',
                                        duration: Durations.Main,
                                        easing: 'outQuad',
                                        // delay: 500
                                    }
                                ]}
                                onComplete={() => {
                                    setStartMedal(true);
                                }}>
                                <div
                                    className="
                                        pb-10 flex justify-center 
                                    ">
                                    <Sticker type="sales" size="wise" />
                                </div>
                                <span
                                    className="
                                        block mb-3 text-[12.5px] font-normal text-[#2D4E54]
                                        md:text-base
                                    " >
                                    ワンランク上の提案へ
                                </span>
                                <h1 className='toppage__detail__block--title'>
                                    公的保険・支援情報を<br className="md:hidden"/>
                                    もっと簡単に<br />
                                    保険営業に『8LIFE』を
                                </h1>
                            </Any>
                            <ul
                                className="
                                    toppage__medal pc gap-x-[10px] justify-center
                                    md:gap-x-[25px]
                                    lg:gap-x-[41px]
                                ">
                                {
                                    Medals.map((each, index) =>
                                        <Any
                                            key={index}
                                            as="li"
                                            start={startMedal}
                                            className="
                                                w-full max-w-[120px]
                                                md:max-w-[120px]
                                                lg:max-w-[143px]
                                            "
                                            from={Vars.Animation.Item.from}
                                            to={[
                                                {
                                                    state: Vars.Animation.Item.to,
                                                    duration: Vars.Animation.Item.Duration,
                                                    easing: Vars.Animation.Item.Easing,
                                                    delay: index * (Vars.Animation.Item.Delay || 0)
                                                }
                                            ]}>
                                            <img
                                                src={each.src}
                                                alt={each.alt}
                                                sizes="100%"
                                            />
                                        </Any>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    
            <div>
                <News />
                <GroupCompany />
                <Free />
                <NaviCard />
                <Step />
                <Ticks />
                <Flows />
                <Features />
                <UseCases />
                <Download />
                <Subsidy />
                <Plan />
                <Note />
                <Qa />
                <Media 
                    className="
                        sm:mt-[20px]
                        md:mt-[40px]
                    "
                />
                <Customer
                    className="
                        mt-[60px] flex justify-center
                        md:mt-[98px]
                        lg:mt-[136px]
                    "
                />
            </div>

            <Transition
                appear
                show={open}
                as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed top-0 left-0 w-screen h-screen bg-black/20 backdrop-blur-sm z-[51]"
                    onClose={() => setOpen(false)}
                    onClick={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100 "
                        leave="ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed top-1/2 left-1/2  -translate-x-[50%] -translate-y-1/2 rounded-xl">
                        {
                            <div
                                className={`
                                    w-screen max-w-[900px] relative aspect-video px-2.5
                                    md:px-6
                                `}>
                                <ReactPlayer
                                    playing
                                    controls
                                    // url={'https://vimeo.com/866243745/b6bef69c95'}
                                    url={'https://vimeo.com/897998327/bd8d524003?share=copy'}
                                    width={'100%'}
                                    height={'100%'}
                                />

                                <button
                                    className="absolute text-lg font-bold text-black -top-[2.5px] right-[30px] sm:text-[22.5px] md:text-3xl"
                                    onClick={() => setOpen(false)}>
                                        <div className="p-1 transition-colors duration-150 rounded-full bg-gray-50 hover:text-red-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[30px] h-w-[30px]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                </button>
                            </div>
                        }
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>

        </Layout>
    );
}

export default IndexPage;

export const Head = () => (
    <Seo
        title="SALES[セールス]プラン | 8LIFE[エイトライフ]"
        description='保険営業にて、お客様に合った公的保険・公的支援の情報を検索し、提供するために作られたプラン。お客様への情報提供がスムーズに進む機能が揃っています。'
        pathname={undefined}
        children={undefined} 
    />
);