import React from 'react';
import Any from '@/components/global/Any';
import Vars from '@/constants/vars';

const FeatureItem = ({ feature, index }: { feature: FeatureType, index: number }) => {
    return (
        <Any
            className='
                w-full relative bg-white py-3 px-1.5 rounded-lg
                sm:py-5 sm:px-3
                md:py-7 md:px-6
                md:last:translate-x-[calc(50%+18px)]
                lg:last:translate-x-[calc(50%+20px)]
            '
            from={Vars.Animation.Item.from}
            to={[
                {
                    state: Vars.Animation.Item.to,
                    duration: Vars.Animation.Item.Duration,
                    easing: Vars.Animation.Item.Easing,
                    delay: index * (Vars.Animation.Item.Delay || 0)
                }
            ]}>
            <div
                className='
                    flex flex-col items-center gap-y-2
                    sm:gap-y-4
                    md:flex-row md:gap-y-0
                '>
                <div
                    className='
                        flex-1 flex justify-end
                    '>
                    <div
                        className='
                            w-full max-w-[200px] h-[107px]
                            sm:max-w-[225px]
                            md:max-w-[200px] md:h-[130px]
                            lg:max-w-[250px] lg:h-[154px]
                        '>
                        <img
                            alt='image'
                            src={feature.icon}
                            className='w-full h-full object-contain'
                        />
                    </div>
                </div>
                <div
                    className='
                        flex-1 flex justify-center
                    '>
                    <div
                        className='
                            text-[14px] text-center font-noto-sans-jp
                            md:text-[19px] md:whitespace-nowrap
                            lg:text-[25px]
                        '>
                        {feature.description}
                    </div>
                </div>
            </div>


        </Any>
    );
}

export default FeatureItem;