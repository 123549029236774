import React from 'react';
import PlanItem from '@/components/element/home/plan/PlanItem';
import Any from '@/components/global/Any';
import Trial from '@/components/global/Trial';
import Vars, { Class } from '@/constants/vars';
import DiscountDesktop from '@/assets/images/home/plan/discount_d.png';
import DiscountMobile from '@/assets/images/home/plan/discount_m.png';
import PriceNavi1 from '@/assets/images/home/plan/price_n_1.png';
import PriceNavi2 from '@/assets/images/home/plan/price_n_2.png';
import PriceSales1 from '@/assets/images/home/plan/price_s_1.png';
import PriceSales2 from '@/assets/images/home/plan/price_s_2.png';

const Plans: {
    price: Price,
    type: CardType
}[] = [
    {
        type: 'navi',
        price: {
            month: {
                value: PriceNavi1,
                className: `
                    w-[50%] max-w-[167.38px]
                    md:w-[43%] md:max-w-[213px]
                    lg:max-w-[259px]
                `
            },
            year: {
                value: PriceNavi2,
                className: `
                    w-[69%] max-w-[204.38]
                    md:w-[59%] md:max-w-[278px]
                    lg:max-w-[352px]
                `
            },
        }
    },
    {
        type: 'sales',
        price: {
            month: {
                value: PriceSales1,
                className: `
                    w-[57%] max-w-[170.21px]
                    md:w-[48%] md:max-w-[230px]
                    lg:max-w-[290.71px]
                `
            },
            year: {
                value: PriceSales2,
                className: `
                    w-[64%] max-w-[188.57px]
                    md:w-[54%] md:max-w-[256px]
                    lg:max-w-[325px]
                `
            },
        }
    }
];

const Plan = () => {
    return (
        <section className={Class.Section_Home}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full flex flex-col items-center space-y-6
                        sm:space-y-10
                        md:space-y-12
                    '>
                    <div
                        className='
                            w-full flex flex-col items-center text-center gap-y-5
                            md:gap-y-7
                            lg:gap-y-9
                        '>
                        <Any
                            className='
                                text-[22px] flex flex-col items-center gap-y-3
                                md:text-[32px] md:gap-y-5
                                lg:text-[43px] lg:gap-y-6
                            '
                            from={Vars.Animation.Title.from}
                            to={[
                                {
                                    state: Vars.Animation.Title.to,
                                    duration: Vars.Animation.Title.Duration,
                                    easing: Vars.Animation.Title.Easing
                                }
                            ]}>
                            <h2
                                className='
                                    font-bold text-[#0891B2]
                                '>
                                利用料金
                            </h2>
                        </Any>
                        <Any
                            className='
                                text-[14px]
                                md:text-[17px]
                                lg:text-[20px]
                            '
                            as='span'
                            from={Vars.Animation.Description.from}
                            to={[
                                {
                                    state: Vars.Animation.Description.to,
                                    duration: Vars.Animation.Description.Duration,
                                    easing: Vars.Animation.Description.Easing
                                }
                            ]}
                            >
                            手軽に使える月間利用とおトクな年間利用を<br className='md:hidden'/>
                            ご用意しております。
                        </Any>
                    </div>
                    <div
                        className='
                            w-full flex flex-col items-center gap-y-[46px] pt-[40px] pb-[33px] px-2.5 bg-[#E2FAFE]
                            md:gap-y-[49px] md:py-[49px] md:pb-[43px] md:px-10
                            lg:gap-y-[53px] lg:pt-[58px] lg:pb-[54px]
                        '>
                        <div
                            className='
                                w-full max-w-[338px] relative
                                md:max-w-[1250px]
                            '>
                            <div
                                className='
                                    w-full relative
                                    md:hidden
                                '>
                                <img
                                    src={DiscountMobile}
                                    alt="discount"
                                    sizes='100%'
                                />
                            </div>
                            <div
                                className='
                                    w-full hidden
                                    md:block
                                '>
                                <img
                                    src={DiscountDesktop}
                                    alt="discount"
                                    sizes='100%'
                                />
                            </div>
                        </div>
                        <div
                            id='plan'
                            className='
                                w-full flex flex-col items-center space-y-6 bg-[#E2FAFE]
                                sm:space-y-7
                                md:space-y-10
                                lg:space-y-12
                            '>
                            <div
                                className={`
                                    w-full max-w-[1300px] grid grid-cols-1 gap-y-6 px-2.5
                                    md:grid-cols-2 md:gap-x-5 md:px-5
                                    lg:gap-x-10 lg:px-10
                                `}>
                                {
                                    Plans.map((plan, index) =>
                                        <Any
                                            key={index}
                                            className={`
                                                w-full flex justify-center
                                                ${
                                                    index % 2 === 0 ? 'md:justify-end' : 'md:justify-start'
                                                }
                                            `}
                                            from={Vars.Animation.Item.from}
                                            to={[
                                                {
                                                    state: Vars.Animation.Item.to,
                                                    duration: Vars.Animation.Item.Duration,
                                                    easing: Vars.Animation.Item.Easing,
                                                    delay: index * (Vars.Animation.Item.Delay || 0)
                                                }
                                            ]}>
                                            <PlanItem
                                                type={plan.type}
                                                price={plan.price}
                                            />
                                        </Any>
                                    )
                                }
                            </div>
                        
                            <Trial />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Plan;