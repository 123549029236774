import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Seo from "@/components/Seo";

const Blog = () => {
    const data = useStaticQuery(graphql`
        query Posts {
            allSanityPost(filter: {}, sort: {order: DESC, fields: publishedAt}) {
                edges {
                    node {
                        title
                        mainImage {
                            asset {
                                url
                            }
                        }
                        slug {
                            current
                        }
                        body {
                            _rawChildren
                        }
                    }
                }
            }
        }
    `);
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        // responsive: [
        //   {
        //     breakpoint: 767,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1
        //     }
        //   }
        // ]
    };
    const posts = data.allSanityPost.edges;

    return (
        <section id="blog">
            <div className="blog">
            <div className="default__width">
                <ul className="sp">
                <Slider {...settings}>
                {posts.map((post, index) => {
                    const { title } = post.node;
                    const slug = post.node.slug.current;
                    const Image = post.node.mainImage.asset.url;
                    return (
                        <li key={index} className='content'>
                        <Link to={`/blog/${slug}`}>
                            <div className="blog--img"><img src={Image} alt={title}/></div>
                            <div className="blog--title"><h3>{title}</h3></div>
                            <p className="blog--btn">詳細はこちら</p>
                        </Link>
                        </li>
                    ) })}
                    </Slider>
                </ul>
                <ul className="pc">
                {posts.map((post, index) => {
                    const { title } = post.node;
                    const slug = post.node.slug.current;
                    const Image = post.node.mainImage.asset.url;
                    return (
                        <li key={index} className='content'>
                        <Link to={`/blog/${slug}`}>
                            <div className="blog--img"><img src={Image} alt={title}/></div>
                            <div className="blog--title"><h3>{title}</h3></div>
                            <p className="blog--btn">詳細はこちら</p>
                        </Link>
                        </li>
                    ) })}
                </ul>
            </div>
            </div>
        </section>
    );
};

export default Blog;

export const Head = () => (
  <Seo
      title="Blog | 8LIFE"
  />
);