import { Class } from '@/constants/vars';
import PointerMobile from '@/assets/images/sales/subsidy/pointer_m.png';
import PointerDesktop from '@/assets/images/sales/subsidy/pointer_d.png';
import Content from '@/images/subsidy/content.svg';
import React from 'react';

const Subsidy = () => {
    return (
        <section
            className={`
                ${Class.Section} px-1.5
                sm:px-3
                md:px-4
                lg:px-6
            `}>
            <div
                className='
                    w-full flex justify-center
                '>
                <div
                    className='
                        w-full relative flex justify-center max-w-[540px]
                        md:max-w-[1100px]
                    '>
                    <div
                        className='
                            w-full flex flex-col items-center space-y-6
                            sm:space-y-9
                            md:space-y-12
                            lg:space-y-[60px]
                        '>
                        <div
                            className='
                                w-full flex flex-col items-center gap-x-3
                                md:flex-row md:justify-between md:gap-x-4
                                lg:gap-x-5
                            '>
                            <div
                                className='
                                    w-full flex flex-col items-center gap-y-[25px]
                                    sm:gap-y-[29px]
                                    md:gap-y-[33px]
                                '>
                                <p
                                    className='
                                        text-lg font-bold text-center
                                        sm:text-[22.5px]
                                        md:text-3xl md:whitespace-nowrap
                                    '>
                                    <span
                                        className='
                                            text-[22.5px] text-[#D30D58]
                                            sm:text-3xl
                                            md:text-4xl
                                        '>
                                        IT導入補助金
                                    </span>
                                    <span>の活用で</span>
                                </p>
                                <div
                                    className='
                                        w-full max-w-[272.61px] z-10 translate-y-[3px]
                                        md:w-[90%]
                                    '>
                                    <div
                                        className='
                                            w-full
                                            md:hidden
                                        '>
                                        <img
                                            src={PointerMobile}
                                            alt='pointer'
                                            sizes='100%'
                                        />
                                    </div>
                                    <div
                                        className='
                                            w-full hidden
                                            md:block
                                        '>
                                        <img
                                            src={PointerDesktop}
                                            alt='pointer'
                                            sizes='100%'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                className='
                                    w-full max-w-[600px] flex justify-center items-center bg-[#E2FAFE] py-6 px-6 rounded-xl
                                    sm:py-9 sm:px-9
                                    md:h-full md:py-10 md:px-10
                                    lg:py-12 lg:px-12
                                '>
                                <div
                                    className='
                                        w-full
                                    '>
                                    <img
                                        src={Content}
                                        alt='pointer'
                                        sizes='100%'
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className='
                                text-[11.25px] space-y-3 self-start
                                sm:text-[12.5px] sm:space-y-4
                                md:text-base
                            '>
                            <div>
                                <p>※IT導入補助金の利用条件は下記となります</p>
                                <p>契約期間は2年間、3ID以上のご利用の場合</p>
                            </div>
                            <div>
                                <p>8LIFEは「IT導入補助金」で補助対象となるITツールとして認定され、デジタル化基盤導入類型での利用申請が可能です。</p>
                                <p>補助対象となる中小企業・小規模事業者の方々は、利用費用に対して最大50％まで補助金の交付を受けられます。</p>
                                <p>ご利用に関する諸条件などの詳細についてお気軽にお問い合わせください。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Subsidy;