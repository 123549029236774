import React from 'react';

const Card = ({ card }: { card: FlowType }) => {
    return (
        <div
            className='
                w-full max-w-[330px] relative flex flex-col items-center gap-y-[44px] bg-white border border-[#243133] rounded-[15px] px-2.5 py-[20px]
                md:max-w-[483px] md:gap-y-[40px] md:py-[25px]
                lg:max-w-[636px] lg:gap-y-[36px] lg:rounded-[20px] lg:py-[30px]
            '>            
            <div
                className='
                    w-full flex flex-col items-center text-center gap-y-[1.5px]
                    md:gap-y-[2.5px]
                    lg:gap-y-[5px]
                '>
                <p
                    className='
                        text-[11px] font-bold text-[#06B6D4]
                        md:text-[13px]
                        lg:text-[16px]
                    '>
                    {card.titleTop}
                </p>
                <p
                    className={`
                        text-[14px]
                        md:text-[19px]
                        lg:text-[25px]
                    `}>
                    {card.title}
                </p>
            </div>
            <div
                className='
                    w-full flex flex-wrap justify-center gap-[16px]
                    md:gap-[20px]
                    lg:gap-[24px]
                '>
                {
                    card.points.map((point, index) =>
                        <div
                            key={index}
                            className='
                                w-[80px] flex flex-col items-center gap-y-[10px]
                                md:w-[90px] md:gap-y-[15px]
                                lg:w-[100px] lg:gap-y-[20px]
                            '>
                            <img
                                alt='image'
                                src={point.icon}
                                sizes='100%'
                            />
                            <div
                                className='
                                    text-[14px] text-center
                                    md:text-[15px]
                                    lg:text-[16px]
                                '>
                                {point.description}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Card;