import React from 'react';
import NaviStand from '@/assets/images/sales/navi_card/navi_stand.png';
import Card from '@/components/element/home/hero/Card';
import { twMerge } from 'tailwind-merge';

const Desc = ({ className }: { className?: string }) => {
    return (
        <p
            className={twMerge(`
                text-start leading-[175%]
            `, className)}>
            <span
                className='
                    text-[22px] font-bold text-emphasis
                    md:text-[26px]
                    lg:text-[30px]
                '>
                どんな方でも<br className='md:hidden'/>
                使いやすいプラン
            </span><br className='md:hidden'/>
            <span
                className='
                    text-[16px]
                    md:text-[20px]
                    lg:text-[24px]
                '>
                もございます！<br />
                保険営業以外の方はこちら！
            </span>
        </p>
    );
}

function NaviCard() {
    return (
        <section
            className='
                w-full mt-[4rem] flex justify-center
                md:mt-[5rem]
                lg:mt-[8rem]
            '>
            <div
                className='
                    w-full flex justify-center bg-[#C8F7FF] pt-[33px] pb-[43px] px-2.5
                    sm:px-5
                    md:pt-[40px] md:pb-[50px] md:px-10
                    lg:pt-[48px] lg:pb-[57px]
                '>
                <div
                    className='
                        w-full max-w-[360px] flex flex-col items-center gap-y-[25px]
                        md:max-w-[848px] md:flex-row md:justify-center md:gap-x-[46px]
                        lg:gap-x-[67px]
                    '>
                    <div
                        className='
                            w-full flex justify-center items-center gap-x-[23px]
                            md:w-[17%]
                        '>
                        <div
                            className='
                                w-[15%]
                                md:w-full md:max-w-[99px]
                                lg:max-w-[143px]
                            '>
                            <img
                                src={NaviStand}
                                alt='stand'
                                sizes='100%'
                            />
                        </div>
                        <Desc
                            className='
                                md:hidden
                            '
                        />
                    </div>
                    <div
                        className='
                            w-full flex flex-col items-center
                            md:flex-1 md:items-start md:gap-y-[24px]
                            lg:gap-y-[25px]
                        '>
                        <Desc
                            className='
                                hidden
                                md:block
                            '
                        />
                        <Card
                            type={'navi'}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NaviCard;