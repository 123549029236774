import React from 'react';
import { Class } from '@/constants/vars';
import Routes from '@/constants/routes';
import { Link } from 'gatsby';
import Sticker from '@/components/global/Sticker';
import CardButton from '@/components/element/home/hero/CardButton';
import { twMerge } from 'tailwind-merge';

type UnderlineProps = {
    className?: string,
    lineClassName?: string,
    children: React.ReactNode
}

const WrapUnderline = ({ className, lineClassName, children }: UnderlineProps) => {
    return (
        <div
            className={twMerge(`
                relative
            `, className)}>
            <div
                className={twMerge(`
                    w-full h-[4px] absolute bottom-[7px] left-0 z-0 bg-[#AFCFFE]
                    md:hidden
                `, lineClassName)}>
            </div>
            {children}
        </div>
    );
}

const SalesCard = () => {
    return (
        <section
            className={`
                ${Class.Section_Home} px-2.5
                md:px-5
                lg:px-10
            `}>
            <div
                className='
                    w-full max-w-[338px] flex justify-center
                    md:w-[90%] md:max-w-[625px]
                    lg:max-w-[913px]
                '>
                <Link
                    to={Routes.SALES}
                    rel='sales'
                    className={`
                        group w-full relative text-[#012B32] flex flex-col items-center gap-y-[27px] rounded-[8px] rounded-br-none bg-white pt-[22px] overflow-hidden
                        md:pt-[35px] md:rounded-[14px]
                        lg:pt-[48px] lg:rounded-[20px]
                    `}>
                    <div
                        className={`
                            w-full h-full absolute top-0 left-0 rounded-[8px] transition-colors duration-300 border-[4px] border-[#3866AB]
                            md:border-[8px] md:rounded-[14px]
                            lg:gap-y-7 lg:pt-7 lg:rounded-[20px]
                            group-hover:border-[#305893]
                        `}>
                    </div>
                    <div
                        className='
                            w-full flex flex-col items-center gap-y-[32px] px-2.5
                            md:gap-y-[30px] md:px-5
                            lg:gap-y-[28px] lg:px-10
                        '>
                        <div
                            className='
                                relative flex flex-wrap justify-center items-end gap-y-[7px]
                                md:flex-nowrap md:whitespace-nowrap
                            '>
                            <div
                                className='
                                    w-full h-[6px] absolute bottom-[10px] left-0 z-0 bg-[#AFCFFE] hidden
                                    md:block
                                    lg:h-[8px]
                                '>
                            </div>
                            <div
                                className='
                                    relative w-full flex justify-center
                                    md:w-fit
                                '>
                                <WrapUnderline>
                                    <p
                                        className='
                                            relative font-bold text-[18px]
                                            md:text-[22px]
                                            lg:text-[26px]
                                        '>
                                        <span
                                            className='
                                                text-[#3866AB] text-[22px]
                                                md:text-[27px]
                                                lg:text-[32px] leading-[175%]
                                            '>
                                            保険営業
                                        </span>で8LIFEを使うなら、
                                    </p>
                                </WrapUnderline>
                            </div>
                            <div
                                className='
                                    w-full flex justify-center
                                    md:w-fit md:-translate-y-[13px] md:mr-[6px]
                                '>
                                <WrapUnderline
                                    className='
                                        w-full max-w-[154px]
                                        md:max-w-[169px]
                                        lg:max-w-[184px]
                                    '
                                    lineClassName='
                                        bottom-[-3px]
                                    '>
                                    <Sticker
                                        type='sales'
                                        className='
                                            relative max-w-[154px]
                                            md:max-w-[169px]
                                            lg:max-w-[184px]
                                        '
                                        labelClassName='
                                            text-[9px]
                                            md:text-[10px]
                                            lg:text-[11px]
                                        '
                                        buttonClassName='
                                            text-[18px] px-[5px]
                                            sm:text-[18px]
                                            md:text-[19px] md:px-[5px]
                                            lg:text-[22px] lg:px-[5px]
                                        '
                                        jpTextClassName='
                                            text-[12px]
                                            sm:text-[12px]
                                            md:text-[13px]
                                            lg:text-[15px]
                                        '
                                    />
                                </WrapUnderline>
                            </div>
                            <div
                                className='
                                    relative w-full flex justify-center
                                    md:w-fit
                                '>
                                <WrapUnderline>
                                    <p
                                        className='
                                            relative font-bold text-[18px]
                                            md:text-[22px]
                                            lg:text-[26px] leading-[175%]
                                        '>
                                        がオススメ！
                                    </p>
                                </WrapUnderline>
                            </div>
                        </div>
                        <div
                            className='
                                max-w-[757px]
                            '>
                            <Sticker
                                type='sales'
                                className='
                                    max-w-[126px] float-left -mt-[15px] ml-[9%]
                                    md:max-w-[126px] md:-mt-[13px] md:ml-0 md:mr-[9px]
                                    lg:max-w-[126px] lg:mr-[10px]
                                '
                                labelClassName='
                                    text-[8px]
                                    md:text-[8px]
                                    lg:text-[8px]
                                '
                                buttonClassName='
                                    text-[16px] px-[5px]
                                    sm:text-[16px]
                                    md:text-[16px] md:px-[5px]
                                    lg:text-[16px] lg:px-[5px]
                                '
                                jpTextClassName='
                                    text-[12px]
                                    sm:text-[12px]
                                    md:text-[14px]
                                    lg:text-[16px]
                                '
                            />
                            <p
                                className='
                                    text-center text-[12px] leading-[175%] text-[#2D4E54]
                                    md:text-start md:text-[14px]
                                    lg:text-[16px]
                                '>
                                <span className='w-fit'>は、<span className='text-[#3866AB] font-bold'>保険営業</span>にて、</span><br className='md:hidden'/>
                                お客様に合った公的保険・公的支援の情報を検索し、<br className='md:hidden'/>
                                提供するために作られたプラン。<br className='md:hidden'/>
                                <span className='text-[#3866AB] font-bold'>お客様への情報提供</span>がスムーズに<br className='md:hidden'/>
                                進む機能が揃っています。
                            </p>
                        </div>
                    </div>
                    <div
                        className='
                            w-full relative flex justify-end
                        '>
                        <CardButton
                            type='sales'
                        />
                    </div>
                </Link>
            </div>
        </section>
    );
}

export default SalesCard;