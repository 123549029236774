
import React from 'react';
import Vars, { Class } from '@/constants/vars';
import CheckMobile from '@/assets/images/navi/learn_more/check_m.png';
import CheckDesktop from '@/assets/images/navi/learn_more/check_d.png';
import ContactButton from '@/components/global/ContactButton';
import Any from '@/components/global/Any';

const LearnMore = () => {
    return (
        <section
            className={Class.Section_Home}>
            <div
                className='
                    w-full flex justify-center bg-white py-[40px]
                    md:py-[45px]
                    lg:py-[51px]
                '>
                <div
                    className='
                        w-full max-w-[350px] flex flex-col items-center gap-y-9 px-2.5
                        sm:px-5
                        md:max-w-5xl md:gap-y-0 md:px-10
                        lg:px-14
                    '>
                    <Any
                        className='w-full'
                        from='opacity-0 translate-y-[40px]'
                        to={[
                            {
                                state: 'opacity-100 translate-y-0',
                                duration: 1000,
                                easing: 'outQuart'
                            }
                        ]}>
                        <div
                            className='
                                w-full
                                md:hidden
                            '>
                            <img
                                src={CheckMobile}
                                alt="check"
                                sizes='100%'
                            />
                        </div>
                        <div
                            className='
                                w-full hidden
                                md:block
                            '>
                            <img
                                src={CheckDesktop}
                                alt="check"
                                sizes='100%'
                            />
                        </div>
                    </Any>
                    <div
                        className='
                            w-[80%] max-w-[204px]
                            md:max-w-[352px] md:-translate-y-[calc(100%+10px)]
                            lg:max-w-[500px]
                        '>
                        <Any
                            className='w-full'
                            from='opacity-0 translate-y-[40px]'
                            to={[
                                {
                                    state: 'opacity-100 translate-y-0',
                                    duration: 1000,
                                    easing: Vars.Animation.Button.Easing,
                                }
                            ]}>
                            <ContactButton
                                className='
                                    text-[1rem]
                                    md:text-[1.125rem]
                                    lg:text-[18px] lg:py-[1.25rem] lg:rounded-[0.375rem]
                                '
                            />
                        </Any>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default LearnMore;