import UseCaseItem from '@/components/element/sales/UseCaseItem';
import React, { useState } from 'react';
import p1 from '@/assets/images/sales/p1.png';
import p2 from '@/assets/images/sales/p2.png';
import p3 from '@/assets/images/sales/p3.png';
import c1 from '@/assets/images/sales/c1.png';
import c2 from '@/assets/images/sales/c2.png';
import c3 from '@/assets/images/sales/c3.png';

import user_4 from '@/images/usercase/user_4.png';
import user_5 from '@/images/usercase/user_5.png';
import company_4 from '@/images/usercase/company_user_4.png';
import company_5 from '@/images/usercase/company_user_5.png';
import Vars, { Class as VarsClass } from '@/constants/vars';
import Any from '@/components/global/Any';
import Slider from '@/components/global/Slider';

const Class = {
    'Link': `
        text-[10px] text-[#1A0EAB]
        md:text-[11px]
        lg:text-[12px]

        hover:underline
    `
}

const UseCasePoints: UseCaseType[] = [
    {
        icon: p1,
        name: '宮宇地　覚',
        role: '保険ネットワークセンター',
        companyIcon: c1,
        summary: '情報提供で信頼される募集人になるのに、8LIFEを使えば情報収集に手間がかからない',
        description: '弊社では以前から、お客様への情報提供を重要視して営業を行っており、特に補助金や助成金に関する情報は、個人や法人を問わず話題にしてきました。これまでは情報収集に時間が掛かっていましたが、8LIFEを利用する事で、情報収集から提供までの手間がなくなりました。'
    },
    {
        icon: p2,
        name: '相川　幸一',
        role: '株式会社アイエスボンズ',
        companyIcon: c2,
        summary: 'リファラル営業には信頼関係構築が不可欠',
        description: '8LIFEを活用して顧客に情報提供を行うことで、より深い信頼関係を築くことができました。その結果、地域の子供会会長による公式アカウント案内などを通じて、コミュニティ内での認知度が向上し、学校の先生方にも我々の存在を広めることができました。また、8LIFEを利用することで、NISAやiDeCoなどの最新の金融商品についてもわかりやすく説明することが可能になりました。'
    },
    {
        icon: p3,
        name: '西　信勝',
        role: '株式会社KTN',
        companyIcon: c3,
        summary: '他代理店との差別化として自治体支援情報活用がお客様の役に立つ',
        description: '当社は、他の代理店との差別化を図るために、自治体独自の支援情報を提供しています。この取り組みは、お客様との信頼を築きながら営業上の優位性を確立するために、8LIFEをコミュニケーションツールとして活用する全社戦略の一環です。'
    },
    {
        icon: user_4,
        name: '成田　弘樹',
        role: '株式会社ワンダフルライフ',
        companyIcon: company_4,
        summary: '募集品質の均一化と募集人の福利厚生の為に8LIFEを導入、募集人満足とお客様の満足の好循環を作りたい',
        description: <div>
            <p>私としては、地域の方々への公的支援の情報提供も公的保険と同様に今後求められてくると考えています。当社のように各地に拠点があり、募集人の数も多いと、募集品質という面でも福利厚生という面でも８LIFEのありがたさが一層実感出来ると思っています。</p>
            <a
                href='https://media.joa-holdings.com/posts/nDI4AN9A'
                rel='media'
                target='_blank'
                className={Class.Link}>
                もっと読む(外部サイト)
            </a>
        </div>
    },
    {
        icon: user_5,
        name: '西浦　伸明',
        role: 'ライフマイスター株式会社',
        companyIcon: company_5,
        summary: '目指す金融総合商社、金融商品のワンストップ提供でお客様サービスの充実を図る',
        description: <div>
            <p>募集人にとってはとても有効な情報を簡単に得て、その情報をお客様に分かりやすく伝えできることに大きな価値を感じました。このサービスを導入することで時間効率が大きく高まることも魅力でした。各地のお客様とオンライン面談が出来るようになった為、全国の公的支援情報を8LIFEで即座に調べれることで、募集人のパフォーマンスは高まります。</p>
            <a
                href='https://media.joa-holdings.com/posts/Vonp1c4B'
                rel='media'
                target='_blank'
                className={Class.Link}>
                もっと読む(外部サイト)
            </a>
        </div>
    },
];

function UseCases() {
    const [show, setShow] = useState(1);
    const [showUsers, setShowUsers] = useState(false);

    return (
        <section
            className={`
                ${VarsClass.Section} 
            `}>
            <div
                className='
                    w-full max-w-[1350px] flex justify-center
                '>
                <div
                    className='
                        w-full flex flex-col items-center space-y-6
                        sm:space-y-9
                        md:space-y-10
                    '>
                    <Any
                        from={Vars.Animation.Title.from}
                        to={[
                            {
                                state: Vars.Animation.Title.to,
                                duration: Vars.Animation.Title.Duration,
                                easing: Vars.Animation.Title.Easing
                            }
                        ]}>
                        <p
                            className='
                                text-lg font-bold
                                sm:text-[22.5px]
                                md:text-3xl
                            '>
                            <span>企業様の</span>
                            <span
                                className='
                                    text-[22.5px] text-[#D30D58]
                                    sm:text-3xl
                                    md:text-4xl
                                '>
                                活用事例
                            </span>
                        </p>
                    </Any>

                    <div
                        className='
                            relative w-full h-full flex justify-center px-2.5
                            sm:px-5
                            md:px-10
                        '>
                        {/* this Any tag is just for reference to show users when it's reached */}
                        <Any
                            className='absolute inset-0 pointer-events-none select-none'
                            from='translate-x-0'
                            to={[
                                {
                                    state: 'translate-x-[2.5px]',
                                    duration: 1,
                                    easing: 'linear'
                                }
                            ]}
                            onStart={() => {setShowUsers(true)}}>
                        </Any>

                        <Any
                            start={showUsers}
                            className='
                                w-full max-w-[480px] h-full relative flex justify-center
                                md:max-w-none
                            '
                            from='opacity-0 translate-x-[40px]'
                            to={[
                                {
                                    state: 'opacity-100 translate-x-0',
                                    duration: 1000,
                                    easing: 'outQuad'
                                }
                            ]}
                            >
                            <Slider
                                show={show}
                                easing='outQuart'
                                // duration={1000}
                                duration={2000}
                                auto={true}
                                // timeout={2000}
                                timeout={3000}
                                // timeout={4750}
                                hoverPause={true}
                                direction='right'
                                onWindowResize={(size: number) => {
                                    if (size < 768) {
                                        setShow(1);
                                    } else if (size < 1024) {
                                        setShow(2);
                                    } else {
                                        setShow(3);
                                    }
                                }}>
                                {
                                    UseCasePoints.map((useCase, index) =>
                                        <div
                                            key={index}
                                            className={`
                                                w-full h-full flex-1 mx-[2.5px] 
                                                sm:mx-[5px]
                                                md:w-[400px] md:mx-[7.5px]
                                                lg:mx-[10px]
                                            `}>
                                            <UseCaseItem useCase={useCase} />
                                        </div>
                                    )
                                }
                            </Slider>
                        </Any>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UseCases;